import React, {
  useEffect,
  useState,
  useRef,
  useCallback,
  useMemo,
  useLayoutEffect,
  memo,
  forwardRef,
} from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { escapeRegExp } from "lodash-es";
import { findAll } from "highlight-words-core";

import Loading from "../shared/Loading";
import Popover from "./Popover";

import { apiCall, webMethodCall } from "../utilities/apiCall";
import { getSessionValue, openPage, uniqueItems } from "../utilities/commonHelpers";

import { enumMasterDataType, enumMasterDataUsage } from "../utilities/enums";

import "./Dropdown.scss";
import Icon from "./Icon";

Dropdown.propTypes = {
  name: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
  ]),
  placeholder: PropTypes.string,
  url: PropTypes.string,
  propName: PropTypes.string,
  onChange: PropTypes.func,
  valueKey: PropTypes.string,
  displayKey: PropTypes.string,
  items: PropTypes.array,
  editable: PropTypes.bool,
  id: PropTypes.string,
  defaultOpen: PropTypes.bool,
  dropdownInPopover: PropTypes.bool,
  textFieldChange: PropTypes.bool,
  isMandatory: PropTypes.bool,
  highlightMatch: PropTypes.bool,
  classNameForContainer: PropTypes.string,
};

function Dropdown(props) {
  const {
    valueKey = "Value",
    displayKey = "Display",
    name,
    value = "",
    placeholder,
    editable,
    onChange,
    onBlur,
    closeDropdownOnSelect = true,
    required,
    id,
    defaultOpen = false,
    isGrouping = false,
    isSeperator = false,
    groupKey = "",
    isDataLoading = false,
    canSelectItemOnClick = true, // This flag is because when we click the drodown value, even though we are not loading that value into state, input box is setting the selected value.
    dropdownInPopover = false,
    disableSearch = false,
    tabIndex,
    textFieldChange = false,
    isMandatory = false,
    inputBoxStyle = {},
    classNameForArrow = "axd-down-arrow",
    highlightMatch = true,
    handleClick = undefined,
    classNameForContainer = "",
    disableArrow = false,
    isSelectDropdownItemsOnTab = true,
    disableOptionsArray, // array of IDs to disable in dropdown list (e.g. [1,2,3])
    fromPage = null,
    redirectLink = "",
    showGotoIcon = false,    // flag to decide whether to show the Goto icon
    AddNewOption = false, // This prop will the add new option in dropdown list if the value is not present in the list.
    addNewOptionIconTitle = "" // This prop will set the title of the add new option icon.
  } = props;
  const [isOpen, setIsOpen] = useState();
  const [items, setItems] = useState(props.items ? props.items : []);
  const isDropdownContentLoaded = useRef(
    props.items && props.items.length > 0 ? true : false
  );
  const [isLoading, setIsLoading] = useState(isDataLoading);
  const [focusIndex, setFocusIndex] = useState(-1);
  const [isKeyboardNavigation, setIsKeyboardNavigation] = useState(false);
  const arrowNavigation = useRef(false);

  const dropdownRef = useRef();
  const inputRef = useRef();
  const [inputVal, setInputVal] = useState("");

  useEffect(() => {
    setIsLoading(isDataLoading);
  }, [isDataLoading]);
  useEffect(() => {
    setIsOpen(defaultOpen);
  }, [defaultOpen]);

  const itemsOfGrouped = (items) => {
    if (isGrouping && groupKey != null) {
      const groups = uniqueItems(items.map((x) => x[groupKey]));
      let resultItems = [];
      groups.map((groupName) => {
        let groupingList = items.filter((item) => {
          return item[groupKey] == groupName;
        });
        groupingList.unshift({
          [valueKey]: "",
          [displayKey]: groupName,
          isGroupitem: true,
        });
        resultItems = [...resultItems, ...groupingList];
      });

      if (resultItems.length == 0) {
        resultItems = items;
      }

      return resultItems;
    } else {
      return items;
    }
  };
  const displayItems = useMemo(() => {
    if (
      isOpen &&
      isKeyboardNavigation &&
      inputVal &&
      inputVal.toString().trim() !== ""
    ) {
      const regExp = new RegExp(escapeRegExp(inputVal), "i");
      return itemsOfGrouped(items).filter(
        (item) => regExp.test(item[displayKey]) || item.isGroupitem
      );
    } else {
      return itemsOfGrouped(items);
    }
  }, [items, inputVal, isOpen, isKeyboardNavigation, displayKey]);

  // set input display value based on `value` prop
  const setDisplayValue = useCallback(() => {
    if (valueKey === displayKey) {
      setInputVal(value);
    } else {
      let displayValue = value; // display the `value` prop as it is if any invalid value is entered
      if (isDropdownContentLoaded.current === true) {
        const ddItem = items.find((item) => value === item[valueKey]);
        if (ddItem !== undefined) {
          displayValue = ddItem[displayKey];
        } else {
          displayValue = "";
        }
      }

      setInputVal(displayValue);
    }
  }, [value, items, valueKey, displayKey]);

  // update the local value on prop change
  useEffect(setDisplayValue, [setDisplayValue]);

  const setDropdownValue = useCallback(
    (item) => {
      if (item) {
        setIsKeyboardNavigation(false);
        if (onChange) {
          const updatedValue = editable ? item[displayKey] : item[valueKey];
          onChange({
            target: {
              type: "dropdown",
              name,
              value: updatedValue,
              display: item[displayKey],
              checkValidity: () =>
                !required || (updatedValue && updatedValue !== -1)
                  ? true
                  : false,
              id,
              item, // adding item for to get whole object in onChange
              isNewOption: !items?.find(
                (existingItem) => existingItem[displayKey] === item[displayKey]
              ),
            },
          });
        }
        canSelectItemOnClick && setInputVal(item[displayKey]);
      } else {
        // Display value based on previous selection value. Else, clear the value
        setDisplayValue();
      }
      closeDropdownOnSelect === true && setIsOpen(false);
    },
    [name, editable, onChange, valueKey, displayKey, setDisplayValue, required]
  );

  const handleFocusOut = useCallback(() => {
    if (!editable) {
      // Reset the value to previous value even if any invalid value is entered
      setDropdownValue(null);
    }
    if (onBlur) {
      const updatedValue = editable ? inputVal : props.value;
      onBlur({
        target: {
          type: "dropdown",
          name,
          value: updatedValue,
          display: inputVal,
          checkValidity: () =>
            !required || (updatedValue && updatedValue !== -1) ? true : false,
        },
      });
    }
    setIsOpen(false);
    setIsKeyboardNavigation(false);
  }, [
    onBlur,
    editable,
    name,
    inputVal,
    setDropdownValue,
    required,
    props.value,
  ]);

  const openMenu = useCallback(() => {
    if (isDropdownContentLoaded.current === false && items.length === 0) {
      // Make the API call and then set the dropdown to open
      // Temporarily block any kind of keyboard navigation too
      const listener = (event) => {
        event.preventDefault();
        event.stopPropagation();
      };
      document.addEventListener("keydown", listener, true);

      setIsLoading(true);
      if (
        props.repType === "actRep" ||
        props.repType === "uc" ||
        props.repType === ""
      ) {
        /*webMethodCall("/intranet/Members/Customer/CustomersEdit.aspx/loadReps", "POST", {
                    limitByRep: true,
                    repID: parseInt(getSessionValue("UserID")),
                    from: props.repType,
                })*/ apiCall(`/services/User/Accounts/Master/${parseInt(
          getSessionValue("UserID")
        )}
                            /false/${true}`)
          .then((resp) => {
            document.removeEventListener("keydown", listener, true);
            setItems(resp.content.List);
            setIsOpen(true);
            setIsLoading(false);
            isDropdownContentLoaded.current = true;
          })
          .catch((error) => {
            document.removeEventListener("keydown", listener, true);
            setIsLoading(false);
            console.log(
              `error while fetching rep details for repType: ${props.repType} is ${error}`
            );
          });
      } else {
        const url = props.dataDetails && props.dataDetails.url;
        url  ? apiCall(url)
          .then((res) => {
            document.removeEventListener("keydown", listener, true);
            const masterDataType = props.dataDetails.MasterDataType;
            const masterDataUsage = props.dataDetails.MasterDataUsage;
            function getFirstOption(masterDataType, masterDataUsage) {
              /* Define a default empty option */
              let emptyOption = {};
              if (masterDataUsage === enumMasterDataUsage.Transaction) {
                emptyOption = { [valueKey]: -1, [displayKey]: "" };
              } else {
                emptyOption = { [valueKey]: -1, [displayKey]: "All" };
              }

              switch (masterDataType) {
                case enumMasterDataType.Prefix:
                  emptyOption = { [valueKey]: -1, [displayKey]: "" };
                  break;
                default:
                  return emptyOption;
              }
              return emptyOption;
            }
            let result = [];
            if (props.propName !== undefined)
              result = res.content.Data[props.propName];
            else {
              if (props.unShiftItem) {
                res.content.List.unshift(props.unShiftItem);
              }
              result = res.content.List;
            }
            if (masterDataType !== undefined) {
              result = [
                getFirstOption(masterDataType, masterDataUsage),
                ...result,
              ];
              if (masterDataType === enumMasterDataType.Categories) {
                result = result.filter((r) => r.Display !== "Gifter");
              }
            }

            setItems(result);
            setIsOpen(true);
            setIsLoading(false);
            isDropdownContentLoaded.current = true;
          })
          .catch((error) => {
            document.removeEventListener("keydown", listener, true);
            console.log(`Error while fetching dropdown values`);
            setIsLoading(false);
          }) : setIsLoading(false);
      }
    } else {
      setIsOpen(true);
    }
  }, [displayKey, valueKey, items.length, props]);

  const focusInputElement = () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  // close the dropdown on click anywhere outside
  useEffect(() => {
    if (isOpen) {
      focusInputElement();
      const listener = (event) => {
        if (!dropdownRef.current.contains(event.target)) {
          // Checking for click within dropdown menu when displayed within popover
          if (
            (dropdownInPopover && !menuRef.current.contains(event.target)) ||
            !menuRef.current.contains(event.target)
          ) {
            // Focus out
            handleFocusOut();
          }
        }
      };
      document.addEventListener("pointerdown", listener);

      return () => document.removeEventListener("pointerdown", listener);
    }
  }, [isOpen, dropdownInPopover, handleFocusOut]);

  // update the local value on props change
  useEffect(() => {
    if (props.items) {
      isDropdownContentLoaded.current = true;
      setItems(props.items);
    }
  }, [props.items]);

  // Scroll the focused option into view
  const menuRef = useRef();
  const focusedOptionRef = useRef();
  useLayoutEffect(() => {
    if (
      menuRef.current &&
      focusedOptionRef.current &&
      arrowNavigation.current
    ) {
      scrollIntoView(menuRef.current, focusedOptionRef.current);
    }
  }, [focusIndex]);

  // Keyboard Navigation
  useEffect(() => {
    if ((props.readOnly && props.readOnly === true) || disableSearch) {
    } else {
      const inputEle = inputRef.current;
      const listener = (event) => {
        switch (event.key) {
          case "Tab": {
            if (
              isOpen &&
              isSelectDropdownItemsOnTab &&
              displayItems.length > 0
            ) {
              event.preventDefault(); // stay on the same field
              setDropdownValue(displayItems[focusIndex]);
            } else {
              // Focus out
              handleFocusOut();
            }
            break;
          }
          case "Escape": {
            // close the dropdown
            setIsOpen(false);
            break;
          }
          case "ArrowDown": {
            event.preventDefault(); // prevent cursor movement in the input field
            arrowNavigation.current = true;
            if (isOpen) {
              // navigate the results down
              if (focusIndex === displayItems.length - 1) {
                setFocusIndex(0);
              } else {
                setFocusIndex(focusIndex + 1);
              }
            } else {
              setFocusIndex(0);
              openMenu();
            }
            break;
          }
          case "ArrowUp": {
            event.preventDefault(); // prevent cursor movement in the input field
            arrowNavigation.current = true;
            if (isOpen) {
              // navigate the results up
              if (focusIndex === 0 || focusIndex === -1) {
                setFocusIndex(displayItems.length - 1);
              } else {
                setFocusIndex(focusIndex - 1);
              }
            } else {
              setFocusIndex(displayItems.length - 1);
              openMenu();
            }
            break;
          }
          case "Home": {
            setFocusIndex(0);
            break;
          }
          case "End": {
            setFocusIndex(displayItems.length - 1);
            break;
          }
          case "Enter": {
            if (isOpen) {
              event.preventDefault(); // prevent form submission if embedded within a form
              if (displayItems.length > 0) {
                if (
                  displayItems[focusIndex].hasOwnProperty("isGroupitem") &&
                  displayItems[focusIndex].isGroupitem
                ) {
                  return;
                }
                setDropdownValue(displayItems[focusIndex]);
              }
            }
            break;
          }
          default:
        }
      };
      inputEle?.addEventListener("keydown", listener);

      return () => inputEle?.removeEventListener("keydown", listener);
    }
  }, [
    isOpen,
    displayItems,
    focusIndex,
    setDropdownValue,
    handleFocusOut,
    openMenu,
    isKeyboardNavigation,
    props.value,
  ]);

  // `onClick` handler for the dropdown
  const toggleDropdown = () => {
    focusInputElement();
    if (isOpen) {
      setIsOpen(false);
    } else {
      openMenu();
      setFocusIndex(-1);
      if (inputRef.current) {
        inputRef.current.select(); // Select the input text so that user can start typing to filter the values
      }
    }
  };

  const handleInputChange = (e) => {
    setInputVal(e.target.value);
    // Focus on the first element on typing in the input box
    setFocusIndex(0);
    if (isKeyboardNavigation === false) {
      setIsKeyboardNavigation(true);
    }
    if (isOpen === false) {
      openMenu();
    }
  };

  //renderes each item in dropdown items list
  const renderList = (item, i) => {
    return (
      <DropdownOption
        key={i}
        ref={focusIndex === i ? focusedOptionRef : null}
        focused={focusIndex === i}
        selected={value ? value === item[valueKey] : false}
        onClick={
          !item.isGroupitem && !disableOptionsArray?.includes(item.ID) // do not allow selection of disabled items in dropdown list
            ? (e) => {
                // do not allow selection of disabled Reps {Dispaly:-DisabledReps-,Value:-2} of SalesReps
                if (item.Value == -2 && item.Display == "-DisabledReps-") {
                  return;
                }
                if(item.Value == -2 && (item.Display == "-- Taxes --" || item.Display == "-- TaxGroups --"))
                 return;
                //don't select in Preflight item "--custom fields--"
                if (item?.PreflightFieldID ==-10000) {
                  return;
                }
                e.stopPropagation();
                setDropdownValue(item);
                focusInputElement();
              }
            : null
        }
        inputValue={inputVal}
        displayValue={item[displayKey]}
        setFocus={
          !item.isGroupitem
            ? () => {
                arrowNavigation.current = false;
                setFocusIndex(i);
              }
            : null
        }
        isGroupItem={item.isGroupitem}
        isGrouping={isGrouping}
        isSeperator={isSeperator}
        highlightMatch={highlightMatch}
        disableOrNot={disableOptionsArray?.includes(item.ID)} // disable items in dropdown list
      />
    );
  };

  //renders dropdown items
  const renderDropdown = () => {
    const dropdownContainer = (
      <ul
        ref={menuRef}
        className={classNames(
          "dropdown",
          props.classNameForContainer,
          dropdownInPopover && "dd-popover"
        )}
      >
        {displayItems.length > 0
          ? displayItems.sort().map((item, i) => renderList(item, i))
          : null}
      </ul>
    );

    if (dropdownInPopover) {
      return (
        <Popover refPosition={inputRef.current.getBoundingClientRect()}>
          {dropdownContainer}
        </Popover>
      );
    } else {
      return dropdownContainer;
    }
  };

  // Add New Value to the dropdown list using plus icon.
  const addNewOptionHandler = (e) => {
    e.stopPropagation();
    let updatedItems = [...items];
    updatedItems.push({
      [displayKey]: inputVal,
      [valueKey]: inputVal,
    });
    setItems(updatedItems);
    setDropdownValue({
      [displayKey]: inputVal,
      [valueKey]: inputVal,
    });
  };

  return (
    <div
      ref={dropdownRef}
      className={classNames("dropdown-container", props.className)}
      role="listbox"
      aria-haspopup="listbox"
      onClick={handleClick ? handleClick : undefined}
    >
      <div
        className={classNames(
          "dropdown-value",
          isMandatory && "novalue-border",
          {
            invalid:
              props.required &&
              (editable ? !inputVal : props.value === "" || props.value === -1),
          }
        )}
        disabled={props.disabled}
        onClick={props.readOnly || props.disabled ? null : toggleDropdown}
      >
        {disableSearch ? (
          <span
            className="dd-display inline-block"
            title={props.hasOwnProperty("title") ? props.title : placeholder}
          >
            {inputVal}
          </span>
        ) : (
          <input
            type="text"
            ref={inputRef}
            value={inputVal}
            id={id}
            placeholder={placeholder}
            disabled={props.disabled}
            className="full-width overflow-ellipsis"
            onChange={!textFieldChange && !props.disabled && handleInputChange}
            readOnly={props.readOnly || disableSearch}
            title={props.hasOwnProperty("title") ? props.title : placeholder}
            autoComplete="off"
            tabIndex={tabIndex}
            style={inputBoxStyle}
          />
        )}
        {showGotoIcon && (
          <span
            className="axd-ext-link-default-visible"
            onClick={(e) => {
              e.stopPropagation();
              openPage(redirectLink);
            }}
          ></span>
        )}
        {AddNewOption &&
          inputRef.current &&
          inputVal &&
          !items.find(
            (item) => item[displayKey].toLowerCase() === inputVal.toLowerCase()
          ) && (
            <Icon
              title={addNewOptionIconTitle}
              iconName="icon-add.svg"
              className="add-option-icon"
              style={{ width: "18px", height: "18px" }}
              onClick={addNewOptionHandler}
            />
          )}
        {disableArrow ? null : (
          <span
            className={`${
              fromPage === "SalesRep"
                ? "axd-down-arrow-default-visible"
                : classNameForArrow
            }`}
          />
        )}
      </div>
      {isOpen === true ? renderDropdown() : null}
      {isLoading && <Loading displayLoadingText={false} />}
      <input type="hidden" name={name} value={value} />
    </div>
  );
}

//component to render each item in dd items list
const DropdownOption = forwardRef(
  (
    {
      displayValue,
      inputValue,
      focused,
      selected,
      setFocus,
      isGrouping = false,
      isGroupItem = false,
      isSeperator = false,
      highlightMatch,
      disableOrNot = false,
      ...props
    },
    ref
  ) => {
    const results = useMemo(
      () =>
        highlightMatch
          ? findAll({
              searchWords: [escapeRegExp(inputValue)],
              textToHighlight: displayValue,
            })
          : displayValue,
      [inputValue, displayValue, highlightMatch]
    );
    const itemStyle = () => {
      if (isGroupItem && isGrouping) {
        return {
          style: { fontWeight: "bold", paddingLeft: "4px", cursor: "default" },
        };
      } else if (isGrouping) {
        return { style: { paddingLeft: "12px" } };
      } else if (disableOrNot) {
        // disable style for items in dropdown list
        return { style: { color: "gray", background: "white" } };
      }
    };
    const itemSpanStyle = (ishighlight, str) => {
      if (ishighlight && displayValue.length !== str.length)
        return { style: { fontWeight: "bold" } };
      else {
        return "";
      }
    };

    return isGroupItem && isGrouping && isSeperator ? (
      <hr />
    ) : (
      <li
        ref={ref}
        role="option"
        aria-selected={selected}
        className={classNames(focused && "focused", selected && "selected")}
        onMouseMove={!(isGroupItem && isGrouping) ? setFocus : null}
        {...itemStyle()}
        {...props}
      >
        {Array.isArray(results) && results.length
          ? results.map((result, index) => {
              const str = displayValue
                ? displayValue.toString().slice(result.start, result.end)
                : "";
              return (
                <span
                  key={index}
                  {...itemSpanStyle(result.highlight, str)}
                  data-user-value={result.highlight ? true : undefined}
                  data-suggested-value={result.highlight ? undefined : true}
                                  title={str}
                >
                  {str}
                </span>
              );
            })
          : displayValue}
      </li>
    );
  }
);

const scrollIntoView = (menuEl, focusedEl) => {
  const menuRect = menuEl.getBoundingClientRect();
  const focusedRect = focusedEl.getBoundingClientRect();
  const overScroll = focusedEl.offsetHeight / 3;

  if (focusedRect.bottom + overScroll > menuRect.bottom) {
    scrollTo(
      menuEl,
      Math.min(
        focusedEl.offsetTop +
          focusedEl.clientHeight -
          menuEl.offsetHeight +
          overScroll,
        menuEl.scrollHeight
      )
    );
  } else if (focusedRect.top - overScroll < menuRect.top) {
    scrollTo(menuEl, Math.max(focusedEl.offsetTop - overScroll, 0));
  }
};

const scrollTo = (el, top) => {
  if ([document.documentElement, document.body, window].includes(el)) {
    window.scrollTo(0, top);
    return;
  }

  el.scrollTop = top;
};

export default memo(Dropdown);
