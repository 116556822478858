import { getRelativeURL } from "../../src/utilities/commonHelpers";

export const URL_PREFIX = `/ui`;
export const API_ADMIN_MASTERS_BYTYPE_GET = `/services/Admin/Masters/MasterData/`;
export const API_ADMIN_SITESETTINGS_SAVE_SPECIFICCOLUMNS = `/services/Admin/SiteSettings/SpecificColumns`;
export const API_ADMIN_SITESETTINGS_INITIATE_EMAILVERIFICATION = `/services/Admin/SiteSettings/InitiateEmailVerification`;
export const API_SUBSCRIPTION_ENABLED_STATUS = `/services/Admin/SiteSettings/subscriptionenabled/`;
export const API_CUSTOM_ACTIONS_BY_CLIENT_ID = `/services/customActions/{clientId}`;
//Contact Edit apis
export const API_AR_CARD_DETAILS = "/services/ar/paymentgateways/card";
export const API_TEMPLATES_DETAILS_GETBYTYPE = `/services/Template/Details/Type/`;
export const API_MASTER_DATA = `/services/Admin/Masters/MasterData/`;
export const API_MASTER_DATA_PRODUCTS = `/services/Admin/Masters/TaskProject/`;

export const API_ADMIN_COMPANYDEFAULTS = "/services/Admin/CompanyDefaults/";
export const API_ADMIN_SAVE_EMAILFOOTER = "/services/Admin/EmailFooterSave/";
export const API_ADMIN_GET_EMAILFOOTER = "/services/Admin/EmailFooterGet/";
export const API_ADMIN_GENERATE_APIUSER_TOKEN =
  "/services/Authentication/APIUser/";
export const API_USER_ACCOUNTS_GET_MASTERDATA = `/services/User/Accounts/Master/`;
export const API_USER_ACCOUNT_GET_BYDEPT =
  "/services/User/Accounts/Department/";
export const API_ORDERS_DYNAMIC_FIELDS = `/services/Production/Orders/DynamicFields/`;
export const API_USER_ACCOUNTS_GET_AllMASTERDATA = `/services/User/Accounts/MasterUsers/`;
export const API_USER_EA_DETAILS_GET = `/services/production/EA/EADetails`;
export const API_USER_EA_EMAILDETAILS_GET = `/services/production/EA/EmailDetails`;
export const API_USER_EA_EMAILDETAILS_MKMWORKFLOWS_GET = `/services/production/EA/getMKMCounts`;

export const API_USER_CONTACTDEFAULTREPFORPROPOSALANDORDER_GET = `/services/user/default/proposalandorder`;
export const API_TEMPLATES_DELETE = `/services/Template/Details/`;
export const API_TEMPLATES_SAVE = `/services/Template/Details/`;
export const API_TEMPLATES_BEEDITOR_SAVEAS = `/services/Template/Details/`;
export const API_TEMPLATES_GETBYID = `/services/Template/Definitions/`;
export const API_TEMPLATE_DETAILS_ACCESS = `/services/Template/Details/Access/`;
export const API_TEMPLATES_TEMPLATE_AUTHENTICATEEDITOR =
  "/services/Template/AuthenticateEditor";
export const API_TEMPLATES_SAEditOnly = `/services/Template/Definitions/`;
export const API_TEMPLATES_PRSAL_INV_STMT = `/services/Template/Definitions/`;
export const API_Contact_ByMailingListId = "/services/crm/contact/mailinglist/";
export const API_Contact_BY_ID = "/services/crm/contact";
export const API_SIMILAR_CONTACTS_By_FirstAndLastName =
  "/services/crm/contacts/similar/-1/-1";
export const API_TEMPLATE_DETAILS_STATUSCHANGE = `/services/Template/Details/StatusChange`;
export const API_TEMPLATES_PRINTTOPDF_GET = `/services/Template/PrintToPDF/`;
export const API_TEMPLATES_DETAILS_MERGEFIELDS = `/services/letter/details/fillMergeFields`;
export const API_TEMPLATES_DETAILS_MERGEFIELDS_PRODUCTION_MODULE = `/services/letter/details/fillMergeFields/productioncontact`;
export const API_CRM_CONTACTS_SENDEMAIL = `/services/crm/contacts/SendEmail`;
export const Template_LineItem_Url = getRelativeURL(
  `/intranet/Members/template/LineItemEdit.aspx`
);
export const WEBSITE_SETUP_URL = getRelativeURL(
  `/intranet/Members/Admin/WebSiteSetup.aspx`
);
export const API_EDITORIAL_TYPES = `/services/admin/editorialtype/all`;
export const API_REPORT_EDITORIAL_SEARCHRESULTS = `/services/Reports/Editorial/SearchResults`;
export const API_ISSUE_AND_YEAR = `/services/admin/issues/magazine/`;
export const API_DISTR_ISSUE = `/services/admin/distr/issues/`;
export const API_REPORT_EDITORIAL_DELETE = `/services/Reports/Editorial/Delete/`;
export const API_REPORT_EDITORIAL_ADD = `/services/Reports/Editorial/Add`;
export const API_REPORT_EDITORIAL_GET = `/services/Reports/Editorial/Get/`;
export const API_GET_MASTER_EDITORIALCONTACTS = `/services/crm/contacts/master/Editorial/Contacts/`;
export const API_REPORT_EDITORIAL_CONTACTS = `/services/Reports/Editorial/Contacts/`;
export const API_REPORT_EDITORIAL_CONTACT_ADD = `/services/Reports/Editorial/Contact/Add/`;
export const API_REPORT_EDITORIAL_CONTACT_DELETE = `/services/Reports/Editorial/Contact/Delete/`;
export const API_REPORT_EDITORIAL_CONTACTS_FILE_DELETE = `/services/Reports/Editorial/Contact/File/Delete/`;
export const API_REPORT_EDITORIAL_CONTACTS_FILE_UPLOAD = `/services/Reports/Editorial/Contact/File/Upload/`;
export const API_REPORT_EDITORIAL_CONTACTS_FILE_GET = `/services/Reports/Editorial/Contact/Files/Get/`;
export const API_REPORT_EDITORIAL_TEXT = `/services/Reports/Editorial/Text/`;
export const API_CUSTOMFIELDS_BY_SECTION = `/services/admin/customfields/locations/`;

//TemplatePreview Related Apis
export const API_TEMPLATES_PROPOSAL_PRIVIEW = `/services/Production/proposals/preview/`;
export const API_TEMPLATES_INVOICE_PRIVIEW = `/services/AR/invoices/preview/`;
export const API_TEMPLATE_INVOICE = `/services/AR/invoice/`;
export const API_AR_STATEMENT_HTML = `/services/AR/statement/html`;
//?type=ClientLetter&id=872&MailingListID=-1"
export const BeeEdtior_Launcher_Url = getRelativeURL(
  `/intranet/Members/template/templateLauncher.aspx`
);
export const TemplateHtmlDisplay_Url = getRelativeURL(
  `/intranet/Members/template/templateLauncher.aspx`
);
// ? type = ClientLetter & id=915 & MailingListID=-1
export const Template_Preview_url = getRelativeURL(
  `/intranet/aspNET/Letter/preview.aspx`
);
export const Template_Setup_url = getRelativeURL(
  `/intranet/aspNET/Letter/advancedSetup.aspx`
);
export const Page_RoutePayment_url = getRelativeURL(
  `/intranet/Members/AR/RoutePaymentGateway.aspx`
);
export const Page_BlankTemplate_url = `/intranet/Members/template/BF-blank-template.json`;
export const Page_HtmlText_Template_url = `/intranet/Templates/BlankTemplate.json`;
export const TemplateDetails_Url = getRelativeURL(`/ui/templateDetails`);
export const ImagePreview = getRelativeURL(`/ui/imagepreview`);

//Distribution
export const API_DISTRIBUTION_DISPLAYTYPES = `/services/Admin/Masters/MasterData/34`;
export const API_DISTRIBUTION_SHIPPINGTYPES = `/services/Admin/Masters/MasterData/35`;
export const API_REPORT_DISTRIBUTION = `/services/Reports/distribution/`;
export const API_REPORT_DISTRIBUTION_PRINT = `/services/Reports/distribution/print/`;
export const API_CRM_CONTACT = `/services/crm/contact`;
export const API_CRM_CONTACT_TYPE = `/services/admin/contactgroups/`;
export const DISTRINUTION_ADVANCED_URL = `${process.env.PUBLIC_URL}/distribution/edit`;

//Bill Search
export const API_PAYMENTPLAN_INSTALLMENTS_GETBYCRITERIA = `/services/PaymentPlan/Installments/Criteria/`;
export const Template_Preview = getRelativeURL(`/ui/template/preview`);
export const API_PAYMENTPLAN_Preview = `/services/PaymentPlan/preview/`;
export const PRINTORPDF = getRelativeURL(
  "/intranet/members/template/Queue.aspx"
);
export const API_PAYMENTPLAN_EMAIL = `/services/PaymentPlan/email/`;
export const Page_Payment_Plan = getRelativeURL(
  "/intranet/Members/production/paymentplan.aspx"
);
export const InvoiceSearch_PaymentDetails = getRelativeURL(
  `/Intranet/Members/AR/PaymentDetails.aspx`
);
export const InvoiceSearch_ReceivePayment = getRelativeURL(
  `/Intranet/Members/AR/ReceivePayments.aspx`
);

//Payment GateWay
export const NAVIGATION_URL = "/intranet/aspNET/Helpers/Pages/Navigation.aspx";
export const DOCUMENT_PREVIEW_URL = "/intranet/aspnet/ar/documentPreview.aspx";
export const ROUTEPAYMENTGATEWAY_URL =
  "/intranet/Members/AR/RoutePaymentGateway.aspx";
export const WEB_ROUTEPAYMENTGATEWAY_URL =
  "/intranet/Mirabel.MM.Web/Members/AR/RoutePaymentGateway.aspx";
export const PAYMENT_GATEWAY_RECEIPT_URL =
  "/intranet/Members/AR/PaymentGatewayReceipt.aspx";

// Reps
export const API_PARTNER_REPS = `/services/User/Contacts/PartnerReps`;
export const Job_View_url = getRelativeURL(`/intranet/members/AR/JobView.aspx`);
export const API_USER_GET_PAGE_VIEW = `/services/crm/User/PageView/`;
export const API_USER_GET_RECENT_VIEW = `/services/SavedSearch/RecentView/`;
//OpenAI
export const API_OPENAI_OPENAILOG_CREATE = `/services/mm/OpenAILog`;
export const API_COMMON_SP_EXECUTE = `/services/admin/common/production/executesp`;
export const API_CATEGORIES_GET_ALL = `/services/Assistant/GetAllCategories`;
export const API_PREPSHEET_DATA_SAVE = `/services/AI/SaveCompanyPrepSheetData`;
export const API_PREPSHEET_DATA_GET = `/services/AI/GetCompanyPrepSheetData`;
export const API_COMPANY_SUMMARRY_DATA_SAVE = `/services/AI/UpdateSummary/Save`;

//Production/Orders(Order Entry Urls)
export const API_ADMIN_SUBPRODUCTTYPES_GET_ALL = "/services/Admin/ProductTypes";
export const API_ADMIN_PRODUCTTYPES_GET_ALL =
  "/services/production/producttype/";
export const API_ADMIN_PRODUCTTYPES_CREATE =
  "/services/production/producttype/insert/";
export const API_ADMIN_PRODUCTTYPES_DELETE =
  "/services/production/producttype/delete/";
export const API_ADMIN_PRODUCTS_SAVE = "/services/Admin/Products/";
export const API_ADMIN_PRODUCTS_GET_MASTERDATA =
  "/services/Admin/Products/Master";
export const API_ADMIN_BUSINESSUNITS_GET_MASTERDATA =
  "/services/admin/businessunits/SubProductType";
export const API_ADMIN_BUSINESSUNITS_GET_BYCRITERIA =
  "/services/admin/BusinessUnits/ByCriteria/";
export const API_ADMIN_RATECARDS_GET_RATESANDCHARGES =
  "/services/admin/ratecards/RatesAndCharges";
export const API_ADMIN_CUSTOMFIELDS_GET_VALUES =
  "/services/admin/customfields/values";
export const API_ADMIN_RATECARDS_GET_PRODUCT_BYID =
  "/services/Admin/Ratecards/Products";
export const API_ADMIN_ADMASTERS_GET_ALL_BYCRITERIA =
  "/services/admin/admasters/criteria";
export const API_ADMIN_MASTERDATA_GET_TAGS =
  "/services/Admin/Masters/MasterData/ProductionTags";
export const API_PRODUCTION_TAGS_REMOVE = "/services/production/jobjacket/tags";
export const API_PRODUCTION_JOBJACKET_STAGES =
  "/services/production/jobjacket/stages";
export const API_CRM_CONTACTS_GET_PRODUCTIONCONTACTS =
  "/services/crm/contacts/ProductionContacts";
export const API_CRM_CONTACTS_GET_BILLINGCONTACTS =
  "/services/crm/contacts/BillingContacts";
export const API_CRM_GET_CONTACTDETAILs_CREATE =
  "/services/Crm/Contacts/BasicDetails";
export const API_PAYMENTPLAN_CUSTOMER_GET = "/services/PaymentPlan/Customer";
export const API_PAYMENTPLAN_ITEM_ADD = "/services/paymentplan/insertion";
export const API_PAYMENTPLAN_ITEM_DELETE = "/services/paymentplan/insertion";
export const API_PRODUCTION_ORDERS_SAVE_COPY = "/services/v2/production/orders";
export const API_PRODUCTION_PROPOSALS_INSERTIONS_SAVE_COPY =
  "/services/v2/production/proposals/insertions";
export const API_PRODUCTION_ORDERS_GET_BYID = "/services/v2/production/orders";
export const API_PRODUCTION_BASIC_ORDERS_GET_BYID =
  "/services/production/orders/basic";
export const API_PRODUCTION_PROPOSALS_INSERTIONS_GET_BYID =
  "/services/v2/production/proposals/insertions";
export const API_ADMIN_ADJUSTMENTS_GET_BYRATECARD =
  "/services/admin/adjustments/ratecard";
export const API_ADMIN_PRODUCTS_GET_SIMILAR =
  "/services/Admin/Products/Similar";
export const API_USER_SIGN_INFO_GET =
  "/services/production/proposals/user/sign";
export const API_ADMIN_PRODUCTCATEGORY_GET_All =
  "/services/Admin/ProductCategories/";
export const API_ADMIN_PRODUCTCATEGORY_SAVE =
  "/services/Admin/ProductCategories";
export const API_ADMIN_PRODUCTCATEGORY_DELETE =
  "/services/Admin/ProductCategories";
export const API_ADMIN_PRODUCTCATEGORY_GET_MASTERDATA =
  "/services/Admin/ProductCategories/Master";
export const API_PRODUCTION_CHECK_INVENTORY_LIMIT =
  "/services/production/checkinventorylimit/";
export const API_ADMIN_PRODUCTTYPE_FIELDS_GET =
  "/services/Admin/ProductTypes/field";

  export const API_ADMIN_LINKED_RATECARDS_GET =
  "/services/Admin/RateCards/Linked";

export const API_ADMIN_RATECARDS_Linked_GET_BYIssueID =
  "/services/Admin/RateCards/ByIssue";

export const API_ADMIN_RATECARDS_TOLINK = "/services/Admin/RateCards/ToLink/";

export const API_PRODUCTION_ORDERENTRY_ADJUSTMENS_GET  = '/services/production/orders/adjustments'
export const API_GET_CLASSIFIED_STYLES = '/services/Admin/ClassifiedStyles'

//order list urls(for order list page)
export const API_PRODUCTION_ORDERS_GET_ALL_BYCUSTOMER =
  "/services/production/orders/bycustomer";
export const API_PRODUCTION_ORDERS_DELETE_BYIDS =
  "/services/production/ordersdelete";
export const Page_Analytics_Dashboard = getRelativeURL("/User/AnalyticsDashboard");
export const Page_order = getRelativeURL(
  "/intranet/Members/Production/AdOrder.aspx"
);
export const Page_Order_Edit = "/Production/OrderEntry/edit";
export const Page_ChangeOrder_Insertion_Edit =
  "/Production/ChangeOrder/Insertions/edit";
export const Page_Orders_Landing = "/production/orderentry";
export const Page_Order_List = "/production/orderlist";
export const Page_JobJacket = getRelativeURL("/ui/Jobjacket");
export const API_CRM_CONTACTS_SEARCH_NAME =
  "/services/crm/contacts/search/Name";
export const API_PRODUCTION_ORDERS_MOVE_OTHERCUSTOMERS =
  "/services/production/orders/move";
export const API_REPORT_CONFIGURATIONS_SAVE_CONFIG =
  "/services/Report/Configurations";
export const API_PRODUCTION_ORDERS_SUMMARY =
  "/services/production/orders/summary";
export const  API_PRODUCTION_PROPOSALS_GET_SUMMARY =
  "/services/production/proposals/summary";
export const  API_PRODUCTION_OPPORTUNITIES_GET_SUMMARY =
  "/services/Opportunities/Contact";
export const API_ADMIN_SITESETTINGS_GET_CURRENTDATETIME =
  "/services/Admin/SiteSettings/currentdatetime";
export const Page_New_Job_Jacket = "/jobjacket?orderid=";
export const Batch_Update = getRelativeURL(
  "/Intranet/Members/Production/BatchOrderUpdate.aspx"
);
export const API_PRODUCTION_ORDERS_CONTRACT =
  "/services/Production/Contracts/ContractToOrders";
export const API_PRODUCTION_ORDERS_CONTRACT_DETAILS =
  "/services/Production/Contracts/Id";

export const API_PRODUCTION_GET_SECURITY = "/services/production/security";
export const API_PRODUCTION_ORDERS_CREATE_QUICKINVOICES =
  "/services/v2/production/orders/QuickInvoices";

//proposal list urls
export const API_PAYMENTPLAN_ACTUALTOTAL_UPDATE =
  "/services/paymentplan/actualtotal";
export const API_PRODUCTION_PROPOSALS_GET_BYCUSTOMER =
  "/services/production/proposals/bycustomer";
export const API_PRODUCTION_PROPOSALS_BULK_DELETE =
  "/services/production/proposals/BulkDelete";
export const API_PRODUCTION_PROPOSALS_SAVE = "/services/production/proposals/";
export const Page_Proposal_List = "/Production/ProposalList";
// export const Page_ChangeOrder_List = "/Production/ChangeOrderList";
export const API_PRODUCTION_PROPOSALS_GET_ESIGNSTATUS =
  "/services/production/proposals/esign";
// const API_USER_ACCOUNTS_GET_MASTERDATA = "/services/User/Accounts/Master";
export const API_ADMIN_ISSUES_GET_BYPRODUCTANDDEFAULTYEARS =
  "/services/admin/issues/products/year/groupbuy/";
export const API_ADMIN__RATECARDS_GET_ALL_DETAILS = "/services/Admin/Ratecards";

//Change Order
export const Page_ChangeOrder_List = "/Production/ChangeOrderList";
export const API_PRODUCTION_CHANGEORDERS_GET_BYCUSTOMER =
  "/services/production/changeorders/bycustomer";
export const API_PRODUCTION_CHANGEORDERS_INSERTION =
  "/services/production/changeorders/insertions";
export const API_PRODUCTION_CHANGEORDERS_GET =
  "/services/production/changeorders/";
export const API_PRODUCTION_CHANGEORDERS_GET_SUMMARY =
  "/services/production/changeorders/summary";
export const API_PRODUCTION_CHANGEORDER_DELETE =
  "/Services/production/changeorder/delete/";
export const API_PRODUCTION_CHANGEORDER_APPLY_CHANGES =
  "/services/production/changeorder/submit/";
export const API_PRODUCTION_CHANGEORDERS_INSERTION_DETAILS_GET =
  "/services/production/changeorders/insertions/edit/";
export const API_PRODUCTION_CHANGEORDERS_INSERTION_DETAILS_SAVE =
  "/services/production/changeorder/insertion/save";

// Contacts
export const API_GET_CONTACT_DETAILS = `/services/Crm/Contacts/`;
export const API_CRM_CONTACTS_UPDATE = "/services/Crm/Contacts/Update";
export const API_CRM_CONTACTS_INACTIVATE_CHECK =
  "/services/crm/contact/caninactivate";
export const API_CRM_CONTACTS_PARENT_SUBSIDIARY_MAPPING = `/services/crm/contacts/subsidiary/mapping`;

// User Role

export const API_USER_ROLE_GET_ALL = "/services/User/Roles/";
export const API_USER_ROLE_DELETE = "/services/User/Roles/";
export const API_USER_ROLE_SAVE = "/services/User/Roles/";
export const API_USER_ROLE_GET_BYID = "/services/User/Roles/";
export const API_USER_ROLE_GET_MASTER = "/services/User/Roles/Master";
export const API_USER_BATCH_UPDATE = "/services/User/Batch/";

// Opportunity

export const API_PRODUCTION_PROPOSALS_GET_BYCRITERIA =
  "/services/production/proposals/bycriteria/";
export const API_PRODUCTION_PROPOSAL_HISTORY_GET_BYID =
  "/services/production/proposalhistory/";
export const API_PRODUCTION_PROPOSAL_HISTORY_SAVE =
  "/services/production/proposalhistory/";
export const API_ADMIN_PRODUCTS_GET_BY_BUSINESSUNITID = `/services/Admin/Products/BusinessUnit/`;
export const API_ADMIN_PRODUCTS_GET_BY_OPPORTUNITY_BUSINESSUNITID = `/services/Admin/Opportunity/Products/BusinessUnit/`;
export const API_ADMIN_OPPORTUNITIES_CONTACT = `/services/Crm/Contacts/Contacts/`;
export const API_ADMIN_OPPORTUNITIES_TYPE_GET_ALL = `/services/Admin/Opportunities/Type`;
export const API_USER_ACCOUNTS_GET_ALL = `/services/User/Accounts/`;
export const API_GAM_USERS_GET_ALL = `/services/GAMUser/GetAll`;
export const API_USER_ACCOUNTS_STATUS_UPDATE = `/services/User/Accounts/Status/`;
export const API_USER_ACCOUNTS_SEND_EMAIL = `/services/User/Accounts/SendEmail`;
export const API_ADMIN_OPPORTUNITIES_LOSSREASON_GET_ALL = `/services/Admin/Opportunities/lossreason/`;
export const API_ADMIN_OPPORTUNITIES_SOURCES = `/services/Admin/Opportunities/Opportunity/Source/`;
export const API_ADMIN_OPPORTUNITIES_ADD = `/services/Opportunities`;
export const API_UPDATE_FIELD_OPPORTUNITY = `/services/Opportunities/Field/`; // params:{fieldName:string}/{fieldValue:string}/{opportunityID:int}/{contactID:int}/{userID:int?}
export const API_GET_OPPORTUNITY_DATA = `/services/Opportunities/`;
export const API_OPPORTUNITIES_GET_BYUSERID = `/services/Opportunities/user/`;
export const API_GET_STAGES_DATA = `/services/Admin/Opportunities/Stage/`;
export const API_GET_SEARCH_RESULTS = `/services/opportunities/report/all/`;
export const API_DELTE_OPPORTUNITY = `/services/Opportunities/`;
export const API_SAVED_SEARCH = `/services/SavedSearch/`;
export const API_SEARCH_FIELDS_GET = `/services/admin/common/searchfields/`;
export const API_PRINT_OPPORTUNITIES = `/services/Opportunities/Report/Print/`;
export const API_EXPORT_OPPORTUNITIES = `/services/Opportunities/Report/Export/`;
export const API_OPPORTUNITIES_REPORT_SENDEMAIL = `/services/Opportunities/Report/SendEmail/`;
export const API_OPPORTUNITIES_HISTORY = `/services/Opportunities/History/`;
export const API_USER_SECURITY_CHECK = `/services/Crm/Contacts/`;
export const API_ADMIN_MASTERS_CRUD = `/services/Admin/Masters`;
export const API_ADMIN_CONTACTGROUPS_GET_ALL = `/services/Admin/ContactGroups`;
export const API_ADMIN_CONTACTGROUPS_SAVE = `/services/Admin/ContactGroups`;
export const API_ADMIN_CONTACTGROUPS_DELETE = `/services/Admin/ContactGroups`;
export const API_ADMIN_CONTACTGROUPS_UPDATE = `/services/Admin/ContactGroups/Update/`;
export const API_ADMIN_CONTACTGROUPS_SORTORDER_UPDATE = `/services/Admin/ContactGroups/SortOrder/Update/`;
export const API_ADMIN_CONTACTTYPES_DELETE = `/services/Admin/ContactType`;
export const API_ADMIN_CONTACTTYPES_SAVE = `/services/Admin/ContactType`;

//Proposal
export const Page_Proposal = getRelativeURL(
  `/intranet/Members/Production/Proposal.aspx`
);
export const API_INTERNAL_APPROVAL_STAGES = '/services/production/stages';
export const Page_Proposal_React = `/production/proposal`;

//website-setup urls
export const API_ADMIN_WEBSITE_SETUPLINKS_GET = `/services/mm/WebsiteSetup/Links`;

export const API_ADMIN_ISSUES_GET_ISSUESBYCRITERIA = `/services/admin/issues/bycriteria`; //get issue ids based on products and issue years

//***Default Billing Installments page***
export const API_ADMIN_DEFAULTBILLINGINSTALLMENTS = `/services/Admin/DefaultBillingInstallments/`; //for save list and get list

//*****contact search start*************

//saved search and mylist APIs
export const API_CRM_CONTACTS_SEARCH_SAVE_ADV_SEARCH_CRITERIA = `/services/crm/contacts/search/SaveAdvSearchCriteria`; //2 references
export const API_CRM_CONTACTS_SEARCH_GET_SAVEDSEARCHESLIST = `/services/crm/contacts/search/SavedSearchesList`;
export const API_CRM_CONTACTS_SEARCH_DELETE_SAVEDSEARCHITEM = `/services/crm/contacts/search/SavedSearchItem`;

//activities APIs
export const API_CRM_ACTIVITIES_GET_SUMMARY_BYDATEFILTER =
  "/services/Crm/Activities/SummaryByDateFilter";
export const API_CRM_ACTIVITIES_GET_ALL = `/services/Crm/Activities`;
export const API_CRM_ACTIVITIES_GET_SOURCETYPE = `/services/Crm/Activities/SourceType`;
export const API_ACTIVITIES_GET_NOTES_BY_ACTIVITYID = `/intranet/Members/Customer/CustomersEdit.aspx/GetActivityNotes`;
export const API_CRM_ACTIVITIES_COMPLETE = "/services/Crm/Activities/Complete/";
export const API_CRM_TASKS_GET_DETAILS = `/services/Crm/tasks/Details/`;
export const API_CRM_TASKS_COMPLETE = `/services/Crm/tasks/Complete/`;
export const API_ACTIVITIES_ADD_NOTE_BY_ACTIVITYTYPE =
  "/services/Crm/Activities/Note";
export const API_ACTIVITIES_SAVE_NOTIFICATION =
  "/intranet/Members/Customer/CustomersEdit.aspx/SaveNotification";

//required fields get
export const API_ADMIN_REQUIREDFIELDS_BYPAGENAME = `/services/Admin/RequiredFields/PageName/CustomerEdit`;
export const API_ADMIN_OPPORTUNITY_REQUIREDFIELDS_BYPAGENAME =
  "/services/Admin/RequiredFields/PageName/OpportunityRequired";
export const API_ADMIN_REQUIREDFIELDS_BYDISPLAYPAGE =
  "/services/Admin/RequiredFields/PageName";
export const API_SAVE_ADMIN_REQUIREDFIELDS_BYPAGENAME =
  "/services/Admin/RequiredFields";
//send email
export const API_CRM_CONTACTS_SEARCH_ADVSENDEMAIL = `/services/crm/contacts/search/AdvSendEmail`;
export const API_CRM_EFFICIENCY_ANALYSER_EMAIL = `/services/crm/EA/email`;
export const API_CRM_EFFICIENCY_ANALYSER_MODULES = `/services/Admin/Masters/Modules`;
export const API_CRM_EFFICIENCY_ANALYSER_MODULES_DATA = `/services/production/EA`;
export const API_PRODUCTION_EA_MODULE_DELETE = `/services/production/EA/module/delete`;
export const API_CRM_EFFICIENCY_ANALYSER_DETAILS_DATA_SAVE = `/services/production/ea/details/save`;
export const API_CRM_EFFICIENCY_ANALYSER_DETAILS_DATA_DELETE = `/services/production/ea/details/delete`;
export const API_CRM_EFFICIENCY_ANALYSER_MODULE_DATA_SAVE = `/services/production/EA/module/save`;
export const API_PRODUCTION_MODULE_SENDEMAIL = `/services/production/orders/AdvSearch/sendemail`;
export const API_SALES_MODULE_SENDEMAIL = `/services/Report/Sales/Email`;
export const API_SUBSCRIPTION_MODULE_SENDEMAIL = `/services/Subscription/Email`;
export const API_PROPOSAL_APPROVAL_SENDEMAIL = `/services/production/proposals/Active/Email`;
export const API_GET_GMAIL_SETTINGS = `/services/crm/contacts/search/GmailSettings`;
export const API_ADVSEARCH_CAMPAIGNCONFIGCHECK = `/intranet/Members/Customer/ContactSearch.aspx/CampaignConfigCheck`;
export const API_SHARE_REPORT_SENDEMAIL = `/services/Reports/Share/Email`;
export const API_SHARE_REPORT_GET_DETAILS_BY_ID = `/services/Reports/Share`;
export const API_SHARE_REPORT_GET_RECENT_TEMPLATE_ID = `/services/Reports/Share/RecentTemplate`;
export const API_API_PROPOSAL_APPROVAL_STAGES_SENDEMAIL = `/services/production/proposals/ActiveStage/Email`

//print
export const API_CRM_CONTACTS_ADVANCESEARCH_PRINT = `/services/crm/contacts/advancesearch/Print`;
export const API_SUBSCRIPTION_MODULE_PRINTPDF = "/services/Subscription/Pdf";

//export
export const API_CRM_CONTACTS_ADVANCESEARCH_EXPORTCSV = `/services/crm/contacts/advancesearch/ExportCSV`;
export const API_CRM_CAMPAIGN_SEARCH_EXPORTCSV=`/services/campaign/SearchResults/ExportCSV`;

//to delete selected contacts
export const API_CRM_CONTACTS_CONTACTBULKDELETE = `/services/Crm/Contacts/ContactBulk/`;

//batch update
export const API_CRM_ADV_CONTACTS_BATCHUPDATE_ALLCONTACTS = `/services/crm/contacts/AdvBatchUpdateAllContacts`;

//contact group update

export const API_ADMIN_MULTIPLE_BATCH_UPDATE_CONTACTS=`/services/Contact/MultipleUpdateContacts/`;
export const API_ADMIN_MULTIPLE_BATCH_UPDATE_ALL_CONTACTS=`/services/Contact/GroupBatchUpdate/AllContacts`;
//SiteSetting
export const API_SITESETTINGS_SPECIFICCOLUMNNAME = `/services/Admin/SiteSettings/ColumnNames/`;
export const API_SITESETTINGS_ALL = `/services/Admin/SiteSettings/All`;

export const API_COMPANY_NAME = "/services/crm/contacts/";

//Navigation Logs for react pages
export const API_ADMIN_NAVIGATION_LOGS = "/services/admin/navigations/logs";
export const API_ADMIN_SITESETTING_GET = `/services/Admin/SiteSettings/All`;
export const API_AR_QUICKBOOK_REQUEST_POST =
  "/services/AR/QuickBooks/QueueRequests";
export const API_USER_CONTACTS_CHECK_SECURITY =
  "/services/User/Contacts/Security/Check/";
export const API_USER_ACCOUNTS_GET_SPECIFICCOLUMNS = `/services/User/Accounts/SpecificColumns/`;
export const API_USER_ACCOUNTS_GMAILINFO_GET = `/services/User/Gmail/`;

// JobJacket
export const API_GET_JOB_JACKET_DATA = `/services/production/jobjacket/`;
export const API_GET_PICKUPS_FROM = `/services/production/jobjacket/getpickupfroms/`;
export const API_UPDATE_ORDER_NUMBER = `/services/production/jobjacket/updateadnumber/`;
export const API_ADD_PRODUCTION_CONTACT = `/services/production/jobjacket/updateproductioncontact/`;
export const API_UPDATE_PRODUCTION_FIELDS = `/services/production/jobjacket/updatefield/`;
export const API_PRODUCTION_JOBJACKET_FIELDS_REQUIRED_GET = `/services/Production/jobjacket/Fields/Required/`;
export const API_ADD_NEW_CONTACT = `/intranet/Members/Customer/CustomersEdit.aspx?edit=0&Search=&gscustomerid=-1&parentid=`;
export const API_OPEN_PROPOSAL = `/intranet/Members/Production/Proposal.aspx?ProposalID=`;
export const API_CREATE_CUSTOMER_ACCESS_PORTAL = `/services/crm/portals/create`;
export const API_CHANGE_DESIGN_STATUS = `/services/production/jobjacket/updatestatus/`;
export const API_PRODUCTION_JOBJACKET_SAVE_PRODUCTIONNOTE =
  "/services/production/jobjacket/addproductionnote";
export const  API_UPLOADEDFILENAMES = "/services/production/jobjacket/getfileuploadnames";
export const API_PRODUCTION_JOBJACKET_GET_NOTES =
  "/services/production/jobjacket/notes";
export const API_PRODUCTION_JOBJACKET_SAVE_PRODUCTION_FILE =
  "/services/production/jobjacket/addproductionfile";
export const API_GET_DEFAULT_ORDER_NUMBER = `/services/production/jobjacket/getdefaultordernumber/`;
export const API_GET_CP_ACCESS_STATUS = `/services/mm/clientsettings/specified/`;
export const API_JOBJACKET_FINAL = `/services/production/jobjacket/updatefinal/`;
export const API_GET_STAGE_HISTORY = `/services/production/orders/stagehistory/`;
export const API_UPDATE_STATUS = `/services/production/jobjacket/updatestatus/`;
export const API_UPDATE_STATUS_NOTES = `/services/production/jobjacket/updatefield`;
export const API_FILE_VIEW_URL = `/services/file/fileget`;
export const API_DELETE_PRODUCTION_NOTE = `/services/production/jobjacket/deleteproductionnote`;
export const API_PRODUCTION_JOBJACKET_REMOVE_SHARED_JOB_JACKET =
  "/services/production/jobjacket/RemoveSharedJobJacket";

//MediaKIT
export const API_MEDIAKIT_SAVE_FILE = `/services/SaveMediaKitFileData`;
export const API_MEDIAKIT_GET_FILES = `/services/GetMediaKitFileData`;
export const API_DELETE_MEDIAKIT_FILES = `/services/DeleteMediaKITFile`;
export const API_AIGENERATED_GET_FILES = `/services/GetAIGeneratedFiles`;
export const API_AI_GET_TEMPLATES = `/services/GetAllAITemplates`;
// export const API_SEND_NOTIFICATION = `/services/home/notifications/`;

//QuickBooks
export const API_QUICKBOOKS_SEARCH_RESULTS = "/services/QuickBooks/Search";
export const API_QB_DATA_OPERATIONS = "/services/QuickBooks/";
export const API_PRODUCTS_BYCRITERIA = "/services/Admin/Products/ByCriteria/";
export const API_QB_AUTHENTICATION =
  "/services/QuickBooks/AuthenticateAccessDetails/";
export const API_QUICKBOOK_CONNECT = getRelativeURL(
  "/Intranet/members/quickbooks/oAuthGrant.aspx?AuthQB=true"
);
export const API_SEND_DATA_TO_QB = "/services/QuickBooks/SendToQB/";
export const API_QB_SEND_DATA_STATUS =
  "/services/QuickBooks/Transactions/Status/";
export const API_FETCH_ACCOUNTNCLASSES =
  "/services/QuickBooks/Fetch/AccountNClasses";
export const API_FETCH_CUSTOMERLIST = "/services/QuickBooks/Fetch/CustomerList";
export const API_LINK_ACCOUNTNCLASSES =
  "/services/QuickBooks/Link/AccountNClasses";
export const API_IMPORT_CUSTOMER = "/services/QuickBooks/Fetch/CustomerImport";
export const API_SAVE_LINKED_DATA = "/services/QuickBooks/Link/AccountNClasses";
export const API_LINK_ADERTISER_QBCUSTOMER =
  "/services/QuickBooks/Link/AdvertiserToQBCustomer";
export const API_LINK_ADERTISER_QBCUSTOMER_LIST =
  "/services/QuickBooks/Link/AdvertiserQBCustomerList";
export const PAGE_QB_DATA_SENT = getRelativeURL(
  "/intranet/Members/Reports/FlexReport.aspx?reportcode=QuickBooksDataSent"
);
export const PAGE_QB_SETUP = getRelativeURL("/ui/QuickBooks/Setup");
export const PAGE_QB_FETCHED_DATA = getRelativeURL(
  "/intranet/Members/Reports/FlexReport.aspx?reportcode=QuickBooksFetchDataCheck"
);
export const API_QB_SEND_DATA_EXPORT = "/services/QuickBooks/Search/Export";
export const PAGE_PAYMENTDETAILS_QB = getRelativeURL(
  "/Intranet/Members/AR/PaymentDetails.aspx?"
);

//Subscriptions
//Plan
export const API_ADMIN_SUBSCRIPTION_PLAN_ALL =
  "/services/Admin/Subscription/Plans/All";
export const API_ADMIN_SUBSCRIPTION_PLAN_BY_ID =
  "/services/Admin/Subscription/Plans";
export const API_ADMIN_SUBSCRIPTION_PLAN_SAVE =
  "/services/Admin/Subscription/Plans";
export const API_ADMIN_SUBSCRIPTION_PLAN_COPY =
  "/services/Admin/Subscription/Plans/Copy";
export const API_ADMIN_SUBSCRIPTION_PLAN_DELETE =
  "/services/Admin/Subscription/Plans";
export const API_ADMIN_SUBSCRIPTION_PLAN_STATUS_UPDATE =
  "/services/Admin/Subscription/Plans/StatusUpdate";
export const API_ADMIN_SUBSCRIPTION_PLAN_EXPORT =
  "/services/Admin/Subscription/Plans/Export";
export const API_ADMIN_SUBSCRIPTION_PLAN_ITEM_MASTERDATA =
  "/services/Admin/Subscription/PlanItem/master";
export const API_ADMIN_MASTERDATA_PRICING_MODEL =
  "/services/Admin/Masters/MasterData/PricingModel";
export const API_ADMIN_MASTERDATA_PERIOD_UNIT =
  "/services/Admin/Masters/MasterData/PeriodUnit";
export const API_ADMIN_MASTERDATA_ADDON_EVENT_TYPE =
  "/services/Admin/Subscription/Addons/EventType";
export const API_ADMIN_MASTERDATA_TERM_END_ACTION =
  "/services/Admin/Masters/MasterData/TermEndAction";
export const API_ADMIN_CONTACTTYPES_GET_ALL = "/services/admin/ContactType/";
export const API_ADMIN_SUBSCRIPTION_PLAN_SEARCH =
  "/services/Admin/Subscription/Plans/search";
export const Page_Plan_List = "/subscription/plans/list";
export const Page_Plan_Create = "/subscription/plans/create";
export const Page_Plan_Edit = "/subscription/plans/edit";
export const Page_Order_Plan_List = "/orders/plans/list";
export const Page_Order_Plan_Create = "/orders/plans/create";
export const Page_Order_Plan_Edit = "/orders/plans/edit";

export const Page_Product_Management = getRelativeURL(
  //"/intranet/Members/Admin/Product.aspx"
  "/ui/Admin/product/List"
);
export const API_ADMIN_SUBSCRIPTION_PLAN_GET_AUTO_APPLY_TAXES = "/services/Admin/Subscription/Plans/AutoApplyTaxes";

//Emergency Backup
export const Page_CreateBackup_Plan = "/Admin/EmergencyBackup/create"
export const Page_Backup_Plan_List = "/Admin/EmergencyBackup/list"
export const Page_Backup_Plan_Edit = "/Admin/EmergencyBackup/edit"
export const API_EMERGENCY_BACKUP_PLAN_SAVE = "/services/EB/planSave"
export const API_EMERGENCY_BACKUP_PLAN_GET = "/services/EB/plansGet"
export const API_EMERGENCY_BACKUP_DD_DATA_GET = "/services/EB/dropDownData"
export const API_EMERGENCY_BACKUP_PLAN_STATUS_CHANGE = "/services/EB/planStatus"
export const API_EMERGENCY_BACKUP_SERVER_DETAILS = "/services/ebconnect/getServer"
export const API_EMERGENCY_BACKUP_GET_CONNECTION_STATUS = "/services/EB/getConnectionStatus"
export const API_EMERGENCY_BACKUP_SAVE_SERVER_CREDENTIALS = "/services/ebconnect/saveServerCredentials"
export const API_EBP_PROCESS_FAILEDTASKS = "/services/EB/production/EBP/FailedTasks"
export const API_EBP_GET_PRODUCTS = "/services/EB/production/EBP/getProducts"

//Coupons
export const API_ADMIN_COUPON_GET_ALL =
  "/services/Admin/Subscription/Coupons/All";
export const API_ADMIN_COUPON_GET = "/services/Admin/Subscription/Coupons";
export const API_ADMIN_COUPON_SAVE = "/services/Admin/Subscription/Coupons";
export const API_ADMIN_COUPON_DELETE = "/services/Admin/Subscription/Coupons";
export const API_ADMIN_COUPON_STATUS_UPDATE =
  "/services/Admin/Subscription/Coupons/StatusUpdate";
export const API_ADMIN_COUPON_EXPORT =
  "/services/Admin/Subscription/Coupons/Export";
export const API_ADMIN_COUPON_COPY =
  "/services/Admin/Subscription/Coupons/Copy";
export const API_ADMIN_SUBSCRIPTION_COUPON_SEARCH =
  "/services/Admin/Subscription/Coupons/Search";
export const Page_Couponlist = "/subscription/coupons/list";
export const Page_Coupon_Create = "/subscription/coupons/create";
export const Page_Coupon_Edit = "/subscription/coupons/edit";
export const API_ADMIN_SUBSCRIPTION_COUPON_VALIDATE =
  "/services/Admin/Subscription/Coupons/Validate/";
export const API_ADMIN_SUBSCRIPTION_COUPON_BYPLANANDADDONIDS =
  "/services/Admin/Subscription/Coupons/PlanAndAddonIDs";

//subscriptions
export const PAGE_ADVANCED_SUBSCRIPTION_SEARCH = `${process.env.PUBLIC_URL}/subscriptions/list`;
export const API_SUBSCRIPTION_BYCONTACT = "/services/Subscription/customer";
export const API_ADMIN_SUBSCRIPTION_PLANS =
  "/services/Admin/Subscription/Plans/Master";
export const API_ADMIN_SUBSCRIPTION_PLANADDONS =
  "/services/Admin/Subscription/Addons/Master";
export const API_SUBSCRIPTION_SAVE = "/services/Subscription/TMM";
export const API_SUBSCRIPTION_PLAN_SUMMARY = "/services/Subscription/summary";
export const API_SUBSCRIPTION_CHECKOUT_TEMP =
  "/services/Subscription/checkout/temp";
export const API_ADMIN_SUBSCRIPTION_PLANADDONS_GET_BY_PLAN_ID =
  "/services/Admin/Subscription/Addons/Plans";
export const API_ADMIN_SUBSCRIPTION_COUPONS_GET_BY_PLAN_ID =
  "/services/Admin/Subscription/Coupons/PlanID";
export const API_CRM_CONTACTS_BASIC_DETAILS =
  "/services/Crm/Contacts/BasicDetails";
export const API_SUBSCRIPTION_GET_BY_ID = "/services/Subscription";
export const API_SUBSCRIPTION_UPDATE_SAVE = "/services/Subscription/Update/TMM";
export const API_SUBSCRIPTIONS_SEARCH = "/services/Subscription/Search";
export const API_SUBSCRIPTIONS_BASIC_SEARCH =
  "/services/Subscription/BasicSearch";
export const API_SUBSCRIPTION_DELETE = "/services/Subscription";
export const API_SUBSCRIPTION_NEXTBILLINGDATE =
  "/services/subscription/NextBillingDate";
export const API_SUBSCRIPTION_PAUSE = "/services/Subscription/PauseInitiate";
export const API_SUBSCRIPTION_REFUND = "/services/Subscription/RefundAmount";
export const API_SUBSCRIPTION_REMOVE_PAUSE =
  "/services/Subscription/RemovePause";
export const API_SUBSCRIPTION_RESUME = "/services/Subscription/Resume";
export const API_SUBSCRIPTION_BULK_EDIT =
  "/Services/Subscription/BatchUpdate/Fields";
export const Page_Subscriptionlist = "/subscriptions/list";
export const Page_Subscription_Create = "/subscriptions/create";
export const Page_Subscrption_Edit = "/subscriptions/edit";
export const Page_Subscription_Renew = "/subscriptions/renew";
export const Page_Subscription_InBetween = "/subscriptions/inbetween";
export const Page_Subscription_Copy = "/subscriptions/copy";
export const Page_Subscription_view = "/subscriptions/view";
export const API_SUBSCRIPTION_INFO_GET = "/services/Admin/Subscription/Info";
export const API_SUBSCRIPTION_INFO_SAVE = "/services/Admin/Subscription/Info";
export const API_SUBSCRIPTION_PAYMENT_SAVE =
  "/services/Subscription/Payment/Save";
export const API_SUBSCRIPTION_END_ACTION_UPDATE =
  "/services/Subscription/EndAction/Update";
export const API_SUBSCRIPTION_EXTEND = "/services/Subscription/Extend";
export const API_SUBSCRIPTION_BATCH_PROCESS =
  "/services/Subscription/BatchProcess";
export const API_CRM_CONTACTS_ADDRESS = "/services/Crm/Contacts/Address/";
export const API_SUBSCRIPTION_PROCESS_REPORT =
  "/services/Subscription/ProcessReport";
export const API_SUBSCRIPTION_GENERATE_FUTURE_INVOICE =
  "/services/Subscription/GenerateFutureInvoice/{Source}";
export const API_SUBSCRIPTION_GENERATE_FUTURE_INVOICE_DELETE =
  "/services/Subscription/GenerateFutureInvoice/Delete/{Source}/{SubscriptionID}/{BillingTermID}";
export const API_SUBSCRIPTION_GET_INVOICE_LIST =
  "/services/Subscription/openInvoices";
export const API_SUBSCRIPTION_DATA_GET = "/services/subscription/Data"

//subscriptions & customer portal
export const API_SUBSCRIPTION_BYSTATUS_FORCONTACT =
  "/services/Subscription/customer";
export const API_SUBSCRIPTION_CANCELINITIATE =
  "/services/Subscription/CancelInitiate";
export const API_SUBSCRIPTION_REACTIVATE = "/services/Subscription/Reactivate";
export const API_SUBSCRIPTION_REMOVECANCEL =
  "/services/Subscription/RemoveCancel";
export const API_SUBSCRIPTION_COMPLETE = "/services/Subscription/Complete";
export const API_SUBSCRIPTION_CANCEL = "/services/Subscription/Cancel";
export const API_SUBSCRIPTION_RENEW = "/services/Subscription/Renew";
export const API_SUBSCRIPTION_UPDATE_ENDACTION =
  "/services/Subscription/EndActionUpdate/";
export const API_SUBSCRIPTION_EXPORT = "/services/subscription/export";
export const API_SUBSCRIPTION_PAYMENT_PREPAYCHECK =
  "/services/Subscription/prepaycheck";
export const API_SUBSCRIPTION_BILLINGINFO_BYID =
  "/services/Subscription/billinginfo";
export const API_CLIENT_SETTINGS_GET = "/services/mm/clientsettings/specified/";
export const API_SUBSCRIPTION_CHECKOUT_PAGE_GET =
  "/services/Subscription/CheckoutPages";
export const API_SUBSCRIPTION_SHOW_UPDATE_ENDACTION =
  "/services/Subscription/ShowEndActionUpdate/{source}/{subscriptionID}";
export const API_SUBSCRIPTION_HISTORY_GET = "/services/Subscription/History/";

//Addon
export const API_ADMIN_ADDON_GET_ALL =
  "/services/Admin/Subscription/Addons/All";
export const API_ADMIN_ADDON_DELETE = "/services/Admin/Subscription/Addons";
export const API_ADMIN_SUBSCRIPTION_ADDON_STATUS_UPDATE =
  "/services/Admin/Subscription/Addons/StatusUpdate";
export const API_ADMIN_SUBSCRIPTION_ADDON_EXPORT =
  "/services/Admin/Subscription/Addons/Export";
export const API_ADMIN_SUBSCRIPTION_ADDON_SAVE =
  "/services/Admin/Subscription/Addons";
export const API_ADMIN_SUBSCRIPTION_ADDON_COPY =
  "/services/Admin/Subscription/Addons/Copy";
export const API_ADMIN_SUBSCRIPTION_ADDON_UPDATE =
  "/services/Admin/Subscription/Addons";
export const API_ADMIN_SUBSCRIPTION_ADDON_SEARCH =
  "/services/Admin/Subscription/Addons/search";
export const Page_Addon_List = "/subscription/addons/list";
export const Page_Addon_Create = "/subscription/addons/create";
export const Page_Addon_Edit = "/subscription/addons/edit";

//Subscriptions / FulfilmentPoints
export const API_ADMIN_FULFILMENTS_GET_ALL =
  "/Services/Admin/Subscription/FulfilmentPoints";
export const API_ADMIN_NAMINGRULES_GET =
  "/Services/Admin/Subscription/FulfilmentPoints/NamingRules";
export const API_ADMIN_FULFILMENTPOINTS_SAVE =
  "/services/Admin/Subscription/FulfilmentPoints";
export const API_ADMIN_FULFILMENTPOINTS_UPDATE =
  "/Services/Admin/Subscription/FulfilmentPoints/Update";
export const API_ADMIN_FULFILMENTPOINTS_DELETE =
  "/Services/Admin/Subscription/FulfilmentPoints";
export const API_ADMIN_FULFILMENTPOINTS_ADD_PRODUCTS =
  "/Services/Admin/Subscription/FulfilmentPoints/Products/AddorRemove";
export const API_ADMIN_FULFILMENTPOINTS_PREVIEW =
  "/Services/Admin/Subscription/FulfilmentPoints/Preview";
export const API_ADMIN_CUSTOMFIELDS_GET_ALLMASTERDATA =
  "/Services/Admin/customfields/MasterData";
export const API_ADMIN_CUSTOMFIELDS_GET_ALL = "/Services/Admin/customfields";
export const API_ADMIN_CUSTOMFIELDS_SORT = "/Services/Admin/customfields/sort";
export const API_ADMIN_CUSTOMFIELDS_GET_CALCULATEDFIELDS = "/Services/admin/customfields/calculatedfields/";
export const API_ADMIN_CUSTOMFIELDS_TEST_FORMULA = "/Services/admin/customfields/testformula";

export const API_CUSTOMSAVEFIELD = `/services/admin/customfields/`;
export const API_CRM_GET_ALL_COLUMNS_CONFIGVIEW = "/Services/AllColumns";
export const Page_Fulfilment_List = "/subscription/FulfilmentPoints/List";
export const Page_Fulfilment_Edit = "/subscription/FulfilmentPoints/Edit";
export const API_EXPORT_GET_ALL_COLUMNS = "/Services/AdvSearches/Views/Column/";

//Subscription / Addon Group
export const API_ADMIN_ADDONGROUP_GET_ALL =
  "/Services/Admin/Subscription/AddonGroups";
export const API_ADMIN_ADDONGROUP_SAVE =
  "/Services/Admin/Subscription/AddonGroups";
export const API_ADMIN_ADDONGROUP_UPDATE =
  "/Services/Admin/Subscription/AddonGroups/Update";
export const API_ADMIN_ADDONGROUP_DELETE =
  "/Services/Admin/Subscription/AddonGroups/";
export const API_ADMIN_ADDONGROUP_UNGROUP =
  "/Services/Admin/Subscription/AddonGroups/UnGroup/";

//Cancel Reason
export const API_ADMIN_CANCEL_REASONS_GET_ALL =
  "/services/Admin/Masters/MasterData/CancelReason";
export const API_ADMIN_CANCEL_REASONS_DELETE =
  "/services/Admin/Masters/SubscriptionCancelReason";
export const API_ADMIN_CANCEL_REASON_SAVE =
  "/services/Admin/Masters/SubscriptionCancelReason/true";

//Exception handler
export const API_EXCEPTION_SAVE = `/services/exceptions/save`;
export const Page_ErrorHandler = getRelativeURL(
  `/intranet/Members/AppPages/ErrorHandler.aspx`
);

// Email Details
export const EMAIL_DETAILS_GET = `/services/crm/activities/gmail`;

//Subscription Source
export const API_ADMIN_SUBCRIPTION_SOURCE_GET_ALL =
  "/services/Admin/Subscription/Source/All";
export const API_ADMIN_SUBCRIPTION_SOURCE_DELETE =
  "/services/Admin/Subscription/Source";
export const API_ADMIN_SUBCRIPTION_SOURCE_SAVE =
  "/services/Admin/Subscription/Source";

//Taxes
export const API_ADMIN_TAXES = `/services/Admin/Taxes`;
export const API_ADMIN_TAXES_DELETE = `/services/Admin/Taxes`;
export const API_ADMIN_TAXES_EXPORT = `/services/Admin/Taxes/Export`;
export const API_ADMIN_TAX_GROUPS = `/services/Admin/TaxGroups`;
export const API_ADMIN_TAX_GROUPS_DELETE = `/services/Admin/TaxGroups`;
export const API_ADMIN_CUSTOMER_TAX_DETAILS = `/services/Admin/Customer/TaxExempt/`;
export const API_AUTOMATION_SCHEDULES_HISTORY = '/services/Automation/Schedules/History';

//AR - Invoice Sarch, Invoice Creation
export const API_SAVED_CARD_BY_CONTACT_WITH_BILLTO_GET = `/Services/ar/paymentgateways/ContactAndBilltocard/`;
// Charge Customer Profile
export const API_CHARGE_CUSTOMER_PROFILE = `/Services/AR/PaymentGateways/ChargeProfile/`;
// Log
export const API_AR_PAYMENTGATEWAY_LOG = `/Services/AR/PaymentGateways/Log/`;
export const API_AR_AUTOMATIONLIST =`/services/Automation/Schedules`;
export const API_AUTOMATION_SCHEDULE_SUMMARY_GET = '/services/Automation/ScheduleSummary/';

//Invoice Create
export const API_PRODUCTION_PRODUCTTYPE = `/Services/Admin/ProductTypes/`;
export const API_PRODUCTION_RATECARD = `/Services/Admin/Ratecards/ByCriteria/`;
export const API_PRODUCTION_ISSUERANGE = `/Services/admin/issues/bycriteria`;
export const API_PRODUCTION_PREMIUMPOSITION = `/Services/admin/adpositions/subproducttype/`;
export const API_PRODUCTION_SPECIALSECTION = `/Services/admin/adsections/subproducttype/`;
export const API_PRODUCTION_COMPANYNAME = `/Services/crm/contacts/GetDistinctCustomers/`;
export const API_PRODUCTION_NAME = `/services/crm/contacts/GetCustomersByName/`;
export const API_PRODUCTION_ISSUE_YEAR = `/services/admin/issues/years/bycriteria`;
export const API_RESULT_VIEW_COLUMNS =
  "/services/AdvSearches/ResultViewColumn/";
export const API_CRM_CONTACTS_GET_DISTINCT_AGENCIES_BY_NAME = `/services/crm/contacts/GetAgenciesByCompanyName/`;

export const API_INVOICES_ORDERS_SEARCH = `/Services/AR/Invoices/OrderSearch`;
export const API_AR_INVOICES_CREATE = `/Services/AR/Invoices`;
export const API_AR_MISC_INVOICES_CREATE = `/Services/AR/Invoices/MiscCharge`;
export const API_AR_INVOICES_EXPORTORDERS = `/Services/AR/Invoices/ExportOrders`;
export const API_AR_TEMPLATE = `/Services/Template/MasterData`;
export const API_PARENT_COMPANY_DETAILS= `/services/Admin/Masters/MasterData/ParentCompany`

//Invoice Search
export const API_AR_UNLOCK_INVOICE = "/services/AR/Invoices/Unlock";
export const API_AR_VOID_INVOICES = "/services/AR/Invoices/Void";
export const API_AR_FINANCE_CHARGE_CREATE =
  "/services/AR/Invoices/FinanceCharge ";
export const API_AR_SITESETTING_DATERANGE = `/Services/Admin/SiteSettings/DateRange/`;
export const API_ADMIN_GET_LETTER_CATAGORY =
  "/services/Admin/Masters/MasterData/LetterCategory";
export const API_ADMIN_GET_LETTER_TEMPLATES =
  "/services/Admin/Masters/MasterData/Letter";
export const API_INVOICE_SEARCH = "/services/ar/invoices/search";
export const API_AR_INVOICES_CUSTOMERSEARCH = `/Services/AR/Invoices/CustomerSearch`;
export const API_AR_INVOICES_EMAIL_SEND = "/services/AR/Invoices/Email";
export const API_AR_INVOICES_PRINT = "/services/ar/invoices/print";
export const API_AR_INVOICES_PRINT_TO_PDF = "/services/ar/invoices/pdf";
export const API_AR_INVOICES_STATUS_GET = "/services/ar/invoices/jobstatus";
export const API_SSO_GENERATE_TOKEN = "/services/User/Login/sso/token";
export const Page_Download_Invoice_Search_Print =
  "/intranet/members/files/view.aspx";

//Transaction Report
export const API_AR_TRANSACTION_SEARCH =
  "/services/AR/Reports/Transactions/Search";
export const API_AR_TRANSACTION_EXPORT =
  "/services/AR/Reports/Transactions/Export";

export const INVOICESEARCH_TRANSACTIONREPORT = getRelativeURL(
  `/ui/ar/Invoices/Search`
);

//Payment Transfers Crredit/Debit
export const API_AR_PAYMENTCREDITDEBIT = "/services/AR/Payments/CreditDebit";
export const API_AR_PAYMENTCREDITDEBIT_SEARCH =
  "/services/AR/Payments/CreditDebit/ByCriteria";

//PrepayApply
export const API_AR_PREPAYAPPLY_SEARCH_GET = "/services/AR/PrepayApply/Search";
export const API_AR_PREPAYAPPLY = "/services/AR/PrepayApply";
export const API_AR_PREPAY_EXPORT = "/services/AR/PrepayApply/Export";
export const API_AR_PREPAY_PAYMENT_TRACK = "/services/AR/PrepayApply/Payment/Track";

//Job Search
export const API_AR_JOBSEARCH_GET =
  "/services/Communication/Jobs/SearchCriteria";
export const API_AR_JOBS_EXPORT = "/services/Communication/Jobs/Export";
export const API_AR_JOB_YEARS = "/services/Communication/Jobs/Year";
export const API_AR_JOBSEARCH_GET_BY_ID = "/services/Communication/Jobs/ID";
export const API_AR_JOB_UPDATE = "/services/Communication/Jobs/Update";

//Batch Billing
export const API_AR_BATCHBILLING_SEARCH_GET =
  "/services/AR/PaymentGateways/GetBatchBillingResults/";
export const API_AR_BATCHBILLING_EXPORT =
  "/services/AR/PaymentGateways/BatchBiliing/Export/";
export const API_AR_BATCHBILLING_APPLY_CHARGES =
  "/services/AR/PaymentGateways/ChargeBulkInvoices/";
export const API_AR_BATCHBILLING_APPLY_STATUS =
  "/services/AR/PaymentGateways/BulkInvoicesPaymentTrack/";
export const PROCESSPAYMENTPLAN_BATCHBILLING = getRelativeURL(
  `/ui/paymentplan/installment`
);
export const API_AR_BATCHBILLING_CHECK_CCCONFIGURE_GET =
  "/services/AR/BatchBilling/CCConfigure/check";
//Aging Report
export const API_AR_AGING_SEARCH = "/services/AR/Reports/Aging/Search";
export const API_AR_AGING_EXPORT = "/services/AR/Reports/Aging/Export";

//AWS Storage Billing
export const API_MM_CLIENTS_GET =
  "/services/mm/clients";
export const API_ADMIN_MASTERS_MKM_WORKFLOWS =
  "/services/Admin/Masters/getMKMWorkflows";
  export const API_ADMIN_MASTERS_GET_MKMCAMPAIGNSBYWORKFLOWID = 
  "/services/Admin/Masters/getMKMCampaignsByWorkflowId";
//Statements
export const API_AR_STATEMENT_SEARCH = "/services/AR/statement/search";
export const API_ADMIN_GET_STATEMENT_TEMPLATES =
  "/Services/Template/MasterData/Statement";
export const API_AR_STATEMENT_PRINT = "/services/AR/statement/print";
export const API_AR_STATEMENT_PDF = "/services/AR/statement/pdf";
export const API_AR_STATEMENT_EMAIL = "/services/AR/statement/email";
export const API_AR_STATEMENT_TRANSACTION_MINDATE_GET =
  "/services/AR/statement/Transaction/mindate";

// Order Search
export const API_ADMIN_AD_SIZES = "/services/Admin/adsizes/subproducttype";
export const API_ADMIN_AD_SIZES_BY_CRITERIA =
  "/services/admin/adsizes/criteria/";
export const API_ADMIN_AD_POSITIONS =
  "/services/Admin/adpositions/subproducttype";
export const API_ADMIN_AD_SECTIONS =
  "/services/Admin/adsections/subproducttype";
export const API_ADMIN_AD_SECTIONS_BY_CRITERIA =
  "/services/admin/adsections/criteria/";
export const API_ADMIN_ADPOSITIONS_GET_BYCRITERIA =
  "/services/admin/adpositions/criteria/";
export const API_ADMIN_RATECARDS = "/services/Admin/ratecards/subproducttype";
export const API_ADMIN_AD_COLORS = "/services/Admin/adcolors/subproducttype";
export const API_ADMIN_AD_FREQUENCIES =
  "/services/Admin/adfrequencies/subproducttype";
export const API_PRODUCTION_ORDERS_ADV_SEARCH =
  "/services/production/orders/AdvSearch";
export const API_PRODUCTION_ORDERS_LINK = "/services/production/orders/link";
export const API_PRODUCTION_ORDERS_UNLINK =
  "/services/production/orders/unlink";
export const API_SALES_REPORT_ADV_SEARCH = "/services/Report/Sales";
export const API_REPORTS_SALES_BY_MODULE_GET =
  "/services/Reports/SalesByModule";
export const API_REPORTS_SALES_BY_MODULE_GET_DETAILS =
  "/services/Reports/SalesByModule/Details";
export const API_REPORTS_SALES_BY_MODULE_DETAILS =
  "/services/Reports/SalesByModule/Details/Result";
export const API_REPORTS_SALES_BY_MODULE_RESULTS =
  "/services/Reports/SalesByModule/Result";
export const API_REPORTS_SETTINGS_SALES_BY_MODULE =
  "/services/Reports/SalesByModule/Settings/";
export const API_REPORTS_COPY_GOAL_YEARS = "/services/Users/Goals/Year";
export const API_REPORTS_COPY_GOAL_SAVE = "/services/Users/Goals/Copy";
export const API_REPORTS_USER_GOAL_UPDATE = "/services/Users/Goals";
export const API_SALES_ADMIN_COMPONENTS = "/services/Admin/Components/Type";
export const API_PRODUCTION_ORDERS_SEARCH =
  "/services/production/orders/search";
export const API_PRODUCTION_ORDERS_SEARCH_CUSTOMERS_CRITERIA =
  "/services/production/Orders/Search/customers/criteria";
export const API_PRODUCTION_ORDERS_SEARCH_ORDERS_CRITERIA =
  "/services/production/Orders/Search/orders/criteria";
export const PAGE_ORDERS_ADVANCED_SEARCH = `${process.env.PUBLIC_URL}/production/ordersearch`;
export const API_SEARCH_RESULTS_PRINT = `/services/production/orders/advSearch/Print`;
export const API_JOB_DETAIL_WITHOUT_ORDER_TASK_CREATE = `/services/production/orders/tasks`;
export const API_PRODUCTION_JOBJACKET_DOWNLOAD_ALLFILES = `/services/production/jobjacket/downloadAllFiles/`;
export const API_PRODUCTION_JOBJACKET_DOWNLOAD_ASSETS = `/services/production/jobjacket/downloadAllAssets/`;
export const API_PRODUCTION_GET_INDESIGNFILES_DOWNLOAD_HISTORY = `/services/indesign/getIndesignFilesDownloadHistory`;

//Notes Activities Search
export const API_SEARCH_NOTES_ACTIVITIES = `/services/crm/Activities/ContactNotesCriteria`;
export const API_ACTIVITIES_CALENDAR_VIEW = `/services/crm/Activities/CalendarWiseActivities/`;
export const API_DELETE_NOTES_ACTIVITIES = `/services/Crm/Activities`;
export const API_COMPLETE_NOTES_ACTIVITIES = `/services/Crm/Activities/Complete`;
export const API_CONTACT_NOTES_ACTIVITIES = `/services/Crm/Contacts/Contacts/`;
export const API_DETAILS_NOTES_ACTIVITIES = `/services/Crm/Contacts/BasicDetails/`;
export const API_GET_NOTES_ACTIVITIES = `/services/Crm/Activities/Activity/`;
export const API_UPDATE_NOTES_ACTIVITIES = `/services/Crm/Activities/`;
export const API_CONTACTEDIT_NOTES_ACTIVITIES = getRelativeURL(
  `/ui/contactedit?edit=1&Search=&gsCustomersID=`
);
export const API_GET_PINNED_ACTIVITIES = `/services/Crm/Activities/Pin`;

//Dashboard
export const API_ANALYTICS_DASHBOARD = "/services/Dashboards/";
export const API_ANALYTICS_DASHBOARD_ISDEFAULT =
  "/services/Dashboard/Isdefault";
export const API_ANALYTICS_DASHBOARD_WIDGET_EDIT =
  "/services/Dashboard/WidgetEdit/";
export const API_ANALYTICS_DASHBOARD_WIDGETS_GET =
  "/services/Dashboard/Widgets";
export const API_ANALYTICS_DASHBOARD_WIDGET_DATA_GET = "/services/Widget/Data";
export const API_ANALYTICS_DASHBOARD_SAVE_AS = "/services/Dashboards/SaveAs/";
export const API_ANALYTICS_DASHBOARD_USER_YTD_BOOKINGS =
  "/services/Dashboards/YTD/Bookings/";
export const API_ANALYTICS_DASHBOARD_FILTERS_GET =
  "/services/Dashboard/Filters/";
export const API_ANALYTICS_DASHBOARD_PAGE_DEFAULT_GET =
  "/services/Dashboards/Default";
export const API_DASHBOARD_CALLS_MEETINGS =
  "/services/Dashboards/CallsMeetings/";
export const API_DASHBOARD_QUICK_LINKS = "/services/Dashboards/QuickLinks";
export const API_DASHBOARD_YTD_ACTIVITIES =
  "/services/Dashboards/YTDActivities";
export const API_DASHBOARD_YTD_BOOKINGS = "/services/Dashboards/YTDBookings";
export const API_DASHBOARD_OPPORTUNITIES = "/services/Dashboards/Opportunities";
export const Page_AllNotesAndActivities = getRelativeURL(
  `/ui/Activities/Search`
);
export const Page_CustomerApprovalInternal = getRelativeURL(
  `/ui/CustomerApprovalInternal`
);
export const Page_React_ContactSearch = "/ui/contactsearch";
export const Page_React_Opportunities = "/ui/opportunities";
export const Page_AllYTDActivities = getRelativeURL(
  `/Intranet/Members/reports/sales/activity.aspx`
);
export const Page_AllYTDBookings = getRelativeURL(
  `/Intranet/aspNET/Report/SalesRunsheet.aspx`
);
export const Page_Calendar = getRelativeURL(
  `/intranet/aspnet/webcalendar/calendar.aspx`
);

//Batch Order Update
export const API_UPDATE_BATCH_ORDER = "/services/production/bou/orders";
export const API_ADMIN_CUSTOMFIELDS_UPDATE_FIELD_DATA_BYSELECTEDORDERS =
  "/services/admin/customfields/updatefielddata/selectedorders";
export const API_ADMIN_COMPONENTS_GET_BYTYPE =
  "/services/Admin/Components/Type";
export const API_ADMIN_CUSTOMFIELDS_GET_BYLOCATION =
  "/services/admin/customfields/locations";
export const API_ADMIN_COMPONENTS_GET_BYID =
  "/services/Admin/Components/Component";
export const API_PRODUCTION_GET_DYNAMIC_CONTROL_VALUES =
  "/services/production/bou/dynamic/dropdown";
export const API_PRODUCTION_GET_REP_DROPDOWN =
  "/services/production/bou/rep/dropdown";

//Line Items
export const API_DISPLAY_FIELDS_PRODUCTION_DATA_GET =
  "/services/Template/LineItems/DisplayFields";
export const API_TEMPLATES_DEFINITION_GETBYID =
  "/services/Template/Definitions/";
export const API_TEMPLATES_LINEITEMS = "/services/Template/LineItems/";
export const API_LINE_ITEM_GET = "/services/Template/LineItems/Definition";
export const API_CUSTOM_FIELDS = "/services/admin/customfields/ByCriteria";
export const API_LINEITEM_STYLE_SAVE = "/services/Template/LineItems/Style";
export const API_LINEITEMS_ADD_PAYMENT_SCHEDULE =
  "/services/Template/LineItems/proposal";
export const API_GET_LINEITEM_THEMES = "/services/Template/LineItems/Theme/All";
export const API_LINEITEMS_THEME = "/services/Template/LineItems/Theme";
export const API_LINEITEMS_THEME_SAVE =
  "/services/Template/LineItems/Theme/Save";
export const API_PROPOSAL_TEMPLATE_UPDATE =
  "/services/Production/Proposals/Template/Change";

//MmnNotifications
export const API_MM_NOTIFICATION_ACTIVE_GET_BY_PAGE =
  "/services/mm/notifications/page/";
export const API_MM_NOTIFICATION_DONOTSHOW_POST =
  "/services/mm/notifications/donotshow/";
export const API_MM_DELETE = "/services/mm/notifications/";
export const API_DROPDOWN_VALUES =
  "/services/mm/notifications/websites?_dc=1627897352033";
export const API_MM_NOTIFICATION_SAVE = "/services/mm/notifications/false";
export const API_MM_NOTIFICATIONS_COPY =
  "/services/mm/notifications/true/saveas/";
export const API_PRODUCTION_MODULE_SEND_NOTIFICATION =
  "/services/production/notification";
export const API_GET_PRODUCTION_NOTES_BY_ORDERID_USERID =
  "/services/production/notes/all/";
export const API_SAVE_LINEITEM_CREATIVE =
  "/services/programmatic/saveLineItemCreative";
//ProposalRenew
export const API_PRODUCTION_PROPOSALS_INSERTIONS_GETALL =
  "/services/production/proposals/insertions";

//move insertion positions inside a proposal
export const API_PRODUCTION_PROPOSALS_INSERTIONS_MOVE_INPROPOSAL =
  "/Services/production/proposals/insertions/move";

//delete proposal lineitems
export const API_PRODUCTION_PROPOSALS_INSERTIONES_DELETE =
  "/Services/production/proposals/insertionsdelete";

//Save sales contact in proposals
export const API_PRODUCTION_PROPOSALS_SAVE_SALESCONTACT =
  "/Services/production/proposals/SalesContact";
export const API_PRODUCTION_PROPOSALS_GETCONTACTS =
  "/Services/crm/contacts/Proposal/Contacts";
export const API_PRODUCTION_PROPOSALS_SALESREP =
  "/Services/User/Accounts/Contact/SalesReps";
export const API_PRODUCTION_PROPOSALS_RENEW =
  "/services/production/proposals/renew";
// export const API_ADMIN_ISSUES_GET_BYPRODUCTANDDEFAULTYEARS ="/services/admin/issues/products/year/groupbuy"
// Production Order Stage
export const API_PRODUCTION_ORDER_STAGES = "/services/production/stages";
export const API_PRODUCTION_ORDER_STAGE_UPDATE =
  "/services/production/orders/stage";
export const API_PRODUCTION_PROPOSALS_APPROVALSTAGE_UPDATE = "/services/production/proposals/approvalstage/"

// Production Order Stage
export const API_PRODUCTION_PROSPECTING_STAGES = "/services/prospectingstages";
export const API_PRODUCTION_PROSPECTING_STAGE_UPDATE =
  "/services/prospectingstageupdate";
export const API_CONTACT_PROSPECTING_STAGE_UPDATE =
  "/services/crm/contacts/prospectstage";

// Lead Types
export const API_LEAD_TYPES = "/services/leadtypes";
export const API_LEAD_TYPE_UPDATE = "/services/leadtypeupdate";

// Batch Order Update
export const API_ORDERS_BATCH_UPDATE =
  "/services/production/orders/advSearch/batchorder/fields";
export const API_SALES_ORDERS_BATCH_UPDATE = "/services/production/orders/bulk";
export const API_SALES_SEARCH_RESULTS_PRINT = `/services/Report/Sales/Print`;
export const PAGE_PROJECT_WORKFLOWS = getRelativeURL(
  "/ui/Admin/Stages?type=order"
);
export const PAGE_OPPORTUNITY_STAGES = getRelativeURL(
  "/ui/Opportunities/OpportunityStages"
);
export const PAGE_PRODUCTION_ORDERSEARCH = getRelativeURL(
  "/ui/Production/Ordersearch"
);
export const PAGE_EMAIL_JOB = getRelativeURL("/ui/AR/JobView?JobID=");
export const PAGE_JOB_VIEW_OLD = getRelativeURL(
  "/intranet/members/ar/jobview.aspx?JobID="
);

//Multi Currency Api's
export const API_MULTICURRENCY_GET_DATA = "/services/AR/MultiCurrencies";
export const API_MULTICURRENCY_GET_SUPPORTDATA =
  "/services/AR/MultiCurrencies/SupportedCurrency";
export const API_MULTICURRENCY_EXCHANGERATE =
  "/services/AR/MultiCurrencies/ExchangeRates";
export const API_MULTICURRENCY_GET_RATECARDS =
  "/services/AR/MultiCurrencies/RateCard";

//Payment Gateways
export const API_MULTICURRENCY_PAYMENTGATEWAY =
  "/services/AR/PaymentGateways/Merchants";
export const API_MUTLICURRENCY_ENABEL_POST =
  "/services/Admin/SiteSettings/SpecificColumns";
export const API_MULTICURRENCY_MAPPING =
  "/services/AR/PaymentGateways/Reference";
export const API_SAVE_PAYMENT_GATEWAY = "/services/AR/PaymentGateways/Details";
export const API_AUTHENTICATE_PAYMENT_GATEWAY =
  "/services/AR/PaymentGateways/Athenticate";
export const API_GET_RATECARDS_DATA =
  "/services/Admin/Masters/MasterData/RateCards/-1/1";

//Multi currency Ratecards Api's
export const API_MUTLICURRENCY_SET_RATECARD =
  "/services/Admin/Ratecards/MapAdMaster";
export const API_MULTICURRENCY_GET_SETRATE_DATA =
  "/services/Admin/Ratecards/Rates";
export const API_MULTICURRENCY_GET_SETRATE_FREQUENCY_DATA =
  "/services/admin/admasters/criteria";

// Production module - export to XML
export const API_GET_INDESIGN_TEMPLATES = "/services/production/indesign";
export const API_EXPORT_TO_XML =
  "/services/production/orders/advSearch/export/xml";
export const API_CUSTOM_ACTION = "/services/classifieds/customaction";
export const API_GET_SECRET_BY_TYPE = "/services/admin/common/k";
export const API_GET_SENDERS_LIST = "/services/admin/common/mkmsenderlist";

//Customer Portal - Download file as zip
export const API_CRM_PORTALS_FILE_DOWNLOAD = "/services/crm/portals/Files/";

//Job search Pages
export const JobQueueStatement_Process = getRelativeURL(
  `/Intranet/Members/AR/StatementQueue.aspx`
);
export const JobQueue_Process = getRelativeURL(
  `/Intranet/Members/Template/Queue.aspx`
);

// OutlookUserData API
export const API_GET_ACCESS_TOKEN_BY_REFRESH_TOKEN =
  "/services/admin/common/Admin/GetOutlookAccessToken?refreshToken=";

// User Public API Account
export const PAGE_ADDUSER_DETAILS = "/Users/AddUsers";
export const GET_APIUSERS = "/Services/Authentication/APIUser/All";
export const GET_ACCESS_TOKEN = "/Services/Authentication/APIUser/Token";
export const ADD_USER = "/Services/Authentication/APIUser";
export const API_FORGOT_EMAIL_PASSWORD =
  "/services/Authentication/UserClients/SetForgotPassword";
export const API_TEMPLATES_TEMPLATE_MERGEFIELDS_GET =
  "/services/Template/MergeFields/";
export const API_RESET_EMAIL_PASSWORD = "/services/User/Accounts/SendEmail";
export const API_USER_TOKEN_GENRATION = "/Authentication/APIUser/Token";
export const API_MM_PRODUCT_TYPES = "";
export const API_CRM_CONTACTS_SEARCH_GET_SEARCHFIELDS =
  "crm/contacts/search/SearchFields";
export const API_FILE_URL = "/services/file/fileurl";

//Module Criteria
export const API_MODULE_CRITERIA_GET = "/services/Module/Criteria";
export const API_MODULE_CRITERIA_SAVE = "/services/Module/Criteria/";

// Adcellerant API's
export const API_ADCELLERANT_ORDERS = "/services/DigitalAds/Orders/";
export const API_DIGITALADSADVERTISER_GET = `/services/DigitalAds/Customers/Search`;
export const API_DIGITALADSADVERTISER_DELETE = `/services/DigitalAds/Customers`;
export const API_DIGITALADSADVERTISER_SAVE = `/services/DigitalAds/Customers/`;
export const API_GETDIGITALADSSETUP = `/Services/DigitalAds/Setup/`;
export const API_INITIATE_PA_BACKGROUND = `/services/programmatic/syncAdManagerData`;
export const API_GAM_HISTORY = `/services/programmatic/gamHistory`;
export const API_SaveDigitalAdsSetup = `/Services/DigitalAds/Setup`;
export const API_PA_CREATIVES_ADD = `/Services/programmatic/addCreatives`;
export const API_PA_LINEITEM_CREATIVES_ADD = `/Services/programmatic/addLineItemCreatives`;
export const API_PA_CREATIVES_EDIT = `/Services/programmatic/editCreatives`;
export const API_PA_BILLING_OPTIONS = `/Services/programmatic/BillingOptionGet`;

//Broadstreet API's
export const API_BSA_PUSH_ADVERTISEMENT = `/services/admanager/pushBSAAdvertisement`;
export const API_BSA_GET_ALL_ADVERTISERS = `/services/admanager/getAllBSAAdvertisers`;
export const API_BSA_GET_ALL_ADVERTISEMENTS = `/services/admanager/getAllBSAAdvertisements`;
export const API_BSA_SAVE_ADVERTISEMENT = `/services/admanager/saveBSAAdvertisement`;
export const API_SAVE_BSA_LINEITEM_ADVERTISEMENT = `/services/admanager/saveBSALineItemAdvertisement`;
export const API_LINK_BSA_ADVERTISEMENT_TO_LINEITEM = `/services/admanager/linkBSAAdvertisementtoLineitem`;
export const API_UNLINK_BSA_ADVERTISEMENT = `/services/admanager/unlinkBSAAdvertisement`;

export const API_BSA_PUSH_ZONE = `/services/programmatic/pushBSAZone`;

export const API_S3_FILE_DELETE_URL = "/services/production/file/delete";
export const API_ORDER_CHECK = "/services/production/orders/contracts";

export const API_SYNC_BSA_ADVERTISERS_DATA = `/services/programmatic/syncBSAAdvertisersData`;
export const API_SYNC_BSA_CAMPAIGNS_DATA = `/services/programmatic/syncBSACampaignsData/`;
export const API_UNLINK_BSA_CAMPAIGN = `/services/Programmatic/UnlinkBSACampaign/`;
export const API_LINK_BSA_CAMPAIGN = `/services/Programmatic/linkBSACampaign/`;
export const API_PULL_BSA_ADVERTISEMENTS = `/services/admanager/pullBSAAdvertisements`;
export const API_SYNC_BSA_ZONE = `/services/Programmatic/syncZones/`;
export const API_LINK_BSA_ZONE = `/services/BSA/pullBSAPlacements`;
export const API_UNLINK_BSA_ZONE = `/services/Programmatic/unLinkZones/`;




//TaskList
export const API_DELETE_TASK = `/services/Task/Delete`;
export const API_ADD_TASK = `/services/Task/Save`;
export const API_GET_TASK_BY_ID = `/services/Task/GetById`;
export const API_USER_TASK_SEARCH_BY_CRITERIA = `/services/Task/Criteria`;
export const API_TASKLIST_SEARCH_PRINT ="/services/Task/print";
export const API_TASKLIST_SEARCH_EXPORT ="/services/Task/export";
export const API_TASKS_BATCH_UPDATE =
"/services/Task/batchupdate";
export const API_ADMIN_TASKPRODUCTMASTERS_GET = `/services/Admin/TaskProductMasters/`;

// Modules
export const API_MODULE_SUMMARY_FIELDS_GET_BY_ID = "/services/Module/Summary";
export const API_MODULE_SUMMARY_FIELDS_SAVE = "/services/Module/Summary/";

//Page Settings
export const API_PAGE_SETTINGS_URL = "/services/Reports/Settings/";

//Address Lookup
export const API_USER_ADDRESS_LOOKUP = `/services/CRM/Address/Lookup`;
export const API_ZIP_CODE_LOOKUP = `/services/User/Accounts/Address/Zip/`;

//Inventory Report
export const API_PRODUCTION_INVENTORY_REPORT_BYCRITERIA =
  "/services/Reports/Inventory";
export const API_PRODUCTION_INVENTORY_REPORT_DETAILS =
  "/services/Reports/Inventory/Details/"; //Schedules
export const API_ADMIN_SCHEDULES_GET_ALL = `/services/admin/schedules/`;

//Proposal
export const API_PRODUCTION_PROPOSALS_GET_DOC_SIGN_INFO = `/services/production/proposals/doc/info/`;
export const API_PRODUCTION_PROPOSALS_SEND_RS_DOC_FOR_SIGN =
  "/services/production/proposals/doc/sign/";
export const API_PRODUCTION_PROPOSALS_GET_DOC_HISTORY =
  "/services/production/proposals/doc/history/";
export const API_PRODUCTION_PROPOSALS_TRASH_DOC =
  "/services/production/proposals/trashdoc/";
export const API_PRODUCTION_PROPOSALS_MOVE_LINE_ITEMS =
  "/services/production/proposals/move/lineitems/";
export const API_PRODUCTION_PROPOSALS_UPDATE_RS_STATUS =
  "/services/production/proposals/rstatus/update/";
export const API_PRODUCTION_PROPOSALS_PRINT =
  "/services/Production/proposals/print/";
export const API_PRODUCTION_PROPOSALS_PRINT_TO_PDF =
  "/services/Production/proposals/pdf/";
export const API_TEMPLATES_PROPOSAL_EMAIL =
  "/services/Production/proposals/email/";
export const API_PRODUCTION_PROPOSALS_LINE_ITEM_TEMPLATE_SAVE_AS =
  "/services/production/proposals/lineitem/template/";
export const API_PRODUCTION_PROPOSALS_LINE_ITEM_TEMPLATE_INSERTIONS =
  "/services/production/proposals/lineitem/template/list/";
//Proposal-END

//Subscription Renewal History Get
export const API_SUBSCRIPTION_RENEWAL_HISTORY_BYID = `/services/Subscription/RenewalHistory/`;
//Subscription Renewal History Get -  END

//Campaign Search - Start
export const API_LOAD_SEARCH_FIELDS = `/services/crm/contacts/Advsearch/LoadDetails`;
export const API_LOAD_CUSTOM_FIELDS = `/services/admin/customfields/values`;
export const API_CONTACT_SEARCH_RESULTS = `/services/crm/contacts/search/SearchResults`;
export const API_CRM_CONTACTS_ADVANCESEARCH_GET_SEARCH_RESULTS = `/services/crm/contacts/advancesearch/SearchResults`;
export const API_CAMPAIGN_ADVANCESEARCH_GET_SEARCH_RESULTS = `/services/campaign/SearchResults`;
export const API_CRM_CONTACTS_ADVANCESEARCH_GET_SEARCH_RESULTS_COUNT = `/services/crm/contacts/advancesearch/SearchResults/Count`;
export const API_CRM_CONTACTS_SEARCH_GET_MYCONTACTS_RESULTS = `/services/crm/contacts/search/MyContacts`;
export const API_CRM_CONTACTS_SEARCH_GET_CALLS_MEETINGS_RESULTS = `/services/crm/contacts/search/CallsAndMeetings`;
export const API_CRM_CONTACTS_SEARCH_GET_ALLCALLBACKS_RESULTS = `/services/crm/contacts/search/AllCallbacks`;
export const API_CRM_CONTACTS_SEARCH_GET_ACCOUNTS_RECEIVABLE_RESULTS = `/services/crm/contacts/search/AccountsReceivable`;
export const API_CRM_CONTACTS_SEARCH_GET_ALLCOLUMNS_CONFIGVIEW = `/services/AdvSearches/Views/`;
export const API_CRM_CONTACTS_SEARCH_DELETE_LISTVIEWITEM = `/services/crm/contacts/search/ListViewItem/`;
export const API_CRM_CONTACTS_SEARCH_UPDATE_COLUMNS_CONFIGVIEW = `/services/AdvSearches/Views/Update`;
export const API_CRM_CONTACTS_SEARCH_SAVE_DEFAULTVIEW = `/services/crm/contacts/search/UpdateDefaultView`;
export const API_CRM_CONTACTS_SEARCH_GET_INVOICE_INFO = `/services/AR/Customer/PendingInvoice/`;
export const API_CRM_CONTACTS_SEARCH_GET_ALLDEFAULT_COLUMNS =
  "/services/crm/contacts/search/DefaultColumnNames/-1/ContactInformation,ContactDetails,ContactCommunication,ContactBilling,ContactFinancial/";
export const API_CAMPAIGN_FOLLOWUP_OR_MANUAL = `/services/campaign/`;
export const API_CAMPAIGN_SEND_CANCEL = `/services/campaign/cancel/`;
export const API_CAMPAIGN_GET_MKM_CAMPAIGN_TYPES =
  "/services/campaign/MKMCampaignTypes";
export const API_CAMPAIGN_GET_MKM_CAMPAIGNS = "/services/campaign/MKMCampaigns";
export const API_CAMPAIGN_GET_MKM_OPTOUT = "/services/campaign/MKMOptOuts";
export const API_CAMPAIGN_GET_MKM_PREFERENCES =
  "/services/campaign/MKMPreferences";
export const API_CAMPAIGN_GET_MKM_FOOTERS = "/services/campaign/MKMFooters";
export const API_CAMPAIGN_GET_MKM_FOOTER_HTML_TEXT =
  "/services/campaign/MKMFooterText";

//Campaign Search - End
//Editorial Job Delete in Contact Edit page
export const API_PRODUCTION_ORDER_TASK_DELETE = `/services/contactedit/EditorialJobs/delete/`;

//programmatic ads
export const MAP_ADVERTISER = `/services/admanager/MapPAMMdetails/`;
export const MAP_ADV_ORDERS = `/services/admanager/advertiserOrders/`;
export const API_PROGRAMMATIC_ORDER_SUMMARY =
  "/services/programmatic/CustomerOrders";

//jobtextview
export const API_LETTER_JOB_TEXTVIEW = `/services/Communication/Jobs/TextView`;
//Activity Module Report
export const API_REPORTS_ACTIVITY_MODULE_BYCRITERIA_GET =
  "/services/Reports/ActivityModule/";
export const API_REPORTS_ACTIVITY_MODULE_GET_DETAILS =
  "/services/Reports/ActivityModule/Details";
export const API_REPORTS_ACTIVITY_MODULE_SAVE_DETAILS =
  "/services/Reports/Activities/Settings/";  

export const API_GET_CONTACTIDS_BY_CRITERIA =
  "/services/Crm/Contacts/Criteria/";
export const API_ADMIN_DEPARTMENTS_GET_ALL = "/services/admin/departments/";

//User Account
export const API_USER_ACCOUNTS_GMAIL_SET = `/services/User/Gmail`;
export const API_USER_ACCOUNTS_GET_BYID = `/services/User/Accounts/UserID`;
export const API_USER_ACCOUNTS_SAVE_USER = `/services/User/Accounts/User/`;
export const API_MM_CLIENTSETTINGS_GET_SPECIFIED_BYCLIENTID = `/services/mm/clientsettings/specified`;
export const API_USER_ACCOUNTS_GENERATE_EMAILCAPTUREID = `/services/User/EmailCapture/Generate`;

//Admin products

export const API_ADMIN_PRODUCTS_GET_ALL = "/services/Admin/Products";
export const API_ADMIN_PRODUCTS_DELETE = "/services/Admin/Products/";
export const API_ADMIN_CIRCULATIONS_MAGAZINE_GET_ALL =
  "/services/Admin/Circulations/Magazine";
export const API_ADMIN_PRODUCTS_GET_BYID = "/services/Admin/Products/Product/";
export const API_ADMIN_PRODUCTS_COPY_SAVE = "/services/Admin/Products/Copy/";

//RateCard Allocations
//ADD/EDIT API URLS

export const API_ADMIN_RATECARDS_GET_PRICINGMODEL =
  "/services/Admin/Ratecards/PricingModel/";
export const API_ADMIN_RATECARDS_TIERED_PRICINGMODEL_SAVE =
  "/services/Admin/Ratecards/PricingModel/";
export const API_ADMIN_PRODUCTS_GET_RATECARDS_BYPRODUCT =
  "/services/Admin/Products/Ratecard/";

export const API_ADMIN_RATECARDS_GET_BYID = "/services/Admin/Ratecards/";
export const API_ADMIN_RATECARDS_SAVE = "/services/Admin/Ratecards/";
export const API_ADMIN_RATECARDS_COPY = "/services/Admin/Ratecards/Copy/";
//export const API_ADMIN_ADJUSTMENTS_GET_BYRATECARD='/services/Admin/Products/Ratecard/'(Present already)
export const API_ADMIN_RATECARDS_MAP_ADMASTER =
  "/services/Admin/Ratecards/MapAdMaster/";
//export const API_ADMIN_ADMASTERS_GET_ALL_BYCRITERIA='/services/Admin/Products/Ratecard/'(Present already)
//to get list of ratecards
export const API_ADMIN_RATECARDS_GET_ALL = "/services/Admin/Ratecards/All/";
export const API_ADMIN_RATECARDS_DELETE = "/services/Admin/Ratecards/";

export const API_ADMIN_ADCOLORS_GET_BYSUBPRODUCTTYPE =
  "/services/admin/adcolors/subproducttype/";
export const API_ADMIN_ADCOLORS_SAVE = "/services/admin/adcolors/";
export const API_ADMIN_ADCOLORS_DELETE_BYID = '/services"admin/adcolors/';

export const API_ADMIN_ADSIZES_GET_BYSUBPRODUCTTYPE =
  "/services/admin/adsizes/subproducttype/";
export const API_ADMIN_ADSIZES_SAVE = "/services/admin/adsizes/";
export const API_ADMIN_ADSIZES_DELETE_BYID = "/services/admin/adsizes/";

export const API_ADMIN_ADFREQUENCIES_GET_BYSUBPRODUCTTYPE =
  "/services/admin/adfrequencies/subproducttype/";
export const API_ADMIN_ADFREQUENCIES_SAVE = "/services/admin/adfrequencies/";
export const API_ADMIN_ADFREQUENCIES_DELETE_BYID =
  "/services/admin/adfrequencies/";

export const API_ADMIN_ADPOSITIONS_GET_BYSUBPRODUCTTYPE =
  "/services/admin/adpositions/subproducttype/";
export const API_ADMIN_ADPOSITIONS_SAVE = "/services/admin/adpositions/";
export const API_ADMIN_ADPOSITIONS_DELETE_BYID = "/services/admin/adpositions/";

export const API_ADMIN_ADSECTIONS_GET_BYSUBPRODUCTTYPE =
  "/services/admin/adsections/subproducttype/";
export const API_ADMIN_ADSECTIONS_SAVE = "/services/admin/adsections/";
export const API_ADMIN_ADSECTIONS_DELETE_BYID = "/services/admin/adsections/";

export const API_ADMIN_ADCOLUMNS_GET_BYSUBPRODUCTTYPE =
  "/services/admin/adcolumns/subproducttype/";
export const API_ADMIN_ADCOLUMNS_SAVE = "/services/admin/adcolumns/";
export const API_ADMIN_ADCOLUMNS_DELETE_BYID = "/services/admin/adcolumns/";

export const API_ADMIN_ADINCHES_GET_BYSUBPRODUCTTYPE =
  "/services/admin/adinches/subproducttype/";
export const API_ADMIN_ADINCHES_SAVE = "/services/admin/adinches/";
export const API_ADMIN_ADINCHES_DELETE_BYID = "/services/admin/adinches/";

//Action Pannel
export const API_USER_ACCOUNTS_HISTORY_GET = `/services/User/Accounts/History/`;

//ChangeOrder
export const API_ADMIN_MASTERDATA_CHANGEORDERS_GET =
  "/services/Admin/Masters/MasterData/ChangeOrder/";
export const API_PRODUCTION_CHANGEORDER_FIELDSGET = `/services/production/changeorder/fieldsget/`;
export const API_PRODUCTION_CHANGEORDER_SAVE = `/services/production/changeorder/save`;
export const API_PRODUCTION_CHANGEORDER_SETRATEFIELDCHECK = `/services/production/changeorder/validaterateentries/`;
export const API_PRODUCTION_CHANGEORDER_HISTORY_GET_BY_ORDERID =
  "/services/production/changeorderhistory/";
export const API_TEMPLATES_CHANGEORDER_PREVIEW = `/services/Production/changeorder/preview/`;
export const API_CHANGEORDER_TEMPLATE_UPDATE =
  "/services/Production/ChangeOrder/Template/Change";
export const API_PRODUCTION_CHANGEORDER_SEARCH =
  "/services/production/changeorder/Search";
export const API_PRODUCTION_CHANGEORDER_SEARCH_PRINT =
  "/services/production/changeorders/Search/print";
export const GET_COMPANY_SUMMARY = `/services/AI/Summary/Get/company/customerid/`;
export const GET_LINKEDIN_HTML = `https://chatbot.emailnow.info/scrape/linkedin`;

export const GET_AI_USAGE_REPORT = "/services/AI/UsageReport/Get";

//OpenAIServices
export const CREATE_CHAT = `/api/AI/createchat`;
export const CREATE_STREAM_CHAT = `/api/AI/createstreamchat`;
export const CREATE_STREAM_RUN = `/api/Assistant/createstreamrun`;
export const CREATE_ASSISTANT = `/api/Assistant/createassistant`;
export const CREATE_THREAD = `/api/Assistant/createthread`;
export const ADD_MESSAGE_THREAD = `/api/Assistant/addmessagetothread`;
export const CREATE_RUN = `/api/Assistant/createrun`;
export const CONFIGURE_AND_EXECUTE_THREAD = `/api/Assistant/configureandexecutethread`;
export const getCompanyWebScrapByURL = `/api/WebSummaries/getCompanyWebScrapByURL`;
export const RETRIVE_RUN_STATUS = `/api/Assistant/retrieverunstatus`;
export const RETRIVE_ASSISTANT_RESPONSE = `/api/Assistant/retrieveassistantresponse`;
export const GENERATE_SALES_LETTER = `/api/Assistant/generatesalesletter`;
export const GENERATE_NOTES_SUMMARY = `/api/Assistant/notessummary`;
export const GENERATE_COMPANY_SUMMARY = `/api/AI/generatenotessummary/CustomerID/`;
export const LETTER_WRITER = `/api/Assistant/letterwriter`;
export const GET_WEB_SUMMARIES = `/api/WebSummaries/getWebSummaries`;
export const SAVE_COMPANYDEFAULTS_SUMMARY = `/api/WebSummaries/getComapanyDefaultSummary`;
export const CREATE_FILE = `/api/File/createfile`;
export const CREATE_FILE_FROM_TEXT_CONTENT = `/api/File/createfilefromtextcontent`;
export const DELETE_FILE = `/api/File/Removefile`;


// Sales Data Analysis
export const LOAD_SALES_DATA_TO_ASSISTANT = `/services/Report/Sales/AddFileToAI/`;

//split api endpoints

export const API_PRODUCTION_PROPOSALS_INSERTIONS_SPLIT = "/services/production/proposals/insertions/split/";
export const  API_PRODUCTION_ORDERS_SPLIT = "/services/production/orders/split";

//Preflight api endpoint
export const API_PREFLIGHT_TYPE_DATA = `/services/Admin/Masters/MasterData/58`;
export const API_PREFLIGHT_FILES_GET_ALL = "/services/PreflightImports/Files";
export const API_PREFLIGHT_FILES_SAVE = "/services/PreflightImports/File";
export const API_PREFLIGHT_FIELDS_GET_ALL = "/services/PreflightImports/Fields";
export const API_PREFLIGHT_FIELDS_GET_SAMPLE = "/services/PreflightImports/Fields/Sample";
export const API_PREFLIGHT_FILE_DELETE = "/services/PreflightImports/File";
export const API_PREFLIGHT_IMPORT_GET_LOGS = "/services/PreflightImports/Logs";
export const API_PREFLIGHT_IMPORT_GET_FIELD_MAPPING = "/services/PreflightImports/FieldsMapping";
export const API_PREFLIGHT_IMPORT_TEMP_TABLE_DELETE = "/services/PreflightImports/TempTable";
export const API_PREFLIGHT_IMPORT_FILE_DATA_UPDATE = "/services/PreflightImports/FileData";
export const API_PREFLIGHT_IMPORT_SEND_MAIL = "/services/PreflightImports/SendMail";
export const API_PREFLIGHT_IMPORT_DATA_SUMMARY = "/services/PreflightImports/DataSummary";
export const API_PREFLIGHT_IMPORT_DATA_FIXES = "/services/PreflightImports/PreflightImportFixes";
export const API_PREFLIGHT_IMPORT_GET_DROPDOWNDATA_BY_ID = "/services/PreflightImports/DropdownDataByID";
export const API_PREFLIGHT_INSERT_MASTER_DATA = "/services/PreflightImports/InsertMasterData";
export const API_PREFLIGHT_VALIDATION_LIST = "/services/PreflightImports/MissingMasterData";
export const API_PREFLIGHT_VALIDATION_LIST_SAVE = "/services/PreflightImports/ValidMasterData";
export const API_PREFLIGHT_VALIDATE_WARNINGS="/services/PreflightImports/ValidateWarnings";
export const API_PREFLIGHT_DATA_VERIFICATION_OPTIONS="/services/PreflightImports/DataVerificationOptions";
export const API_PREFLIGHT_DATA_VERIFICATION_UPDATE="/services/PreflightImports/DataVerification";
export const API_PREFLIGHT_DATA_SPLIT_ADDRESS="/services/PreflightImports/SplitAddressIntoSubFields";
export const API_PREFLIGHT_IMPORT_FILE_MASTER_COLUMN_DATA_UPDATE ="/services/PreflightImports/FileMasterColumnData"

//Preflight MediaKit
export const API_PREFLIGHT_MEDIAKITFILES_GET_FILESBASE64= "/services/PreflightImports/MediaKitFile/GetFilesBase64";
export const API_MEDIA_KIT_FILES_GET_ALL = "/services/PreflightImports/MediaKitFiles";
export const API_PREFLIGHT_MEDIA_GET_DATA = "/services/PreflightImports/GetMediaKitData";
export const API_MEDIA_KIT_FILE_DELETE = "/services/PreflightImports/MediaKitFile/Delete";
export const API_PREFLIGHT_MEDIAKIT_SAVE_DATA = "/services/PreflightImports/SaveMediaKitResponse";
export const API_PREFLIGHT_GET_HISTORY = "/services/PreflightImports/History";
export const API_PREFLIGHT_SAVE_HISTORY = "/services/PreflightImports/History";

// Indesign files
export const API_SEND_TO_INDESIGN = `/services/Indesign/SendToIndesign`;

// AdMaster endpoints

export const API_GET_PRODUCT_TYPES = "/services/Admin/ProductTypes/Master";
export const API_GET_MASTER_DATA =
  "/services/Admin/ProductTypes/field/AdMasterList";
export const SERVICE_ADMIN = "/services/admin";

// Column Reorder Save
export const SAVE_COLUMN_REORDER = "/Services/AdvSearches/Views/Columns"


export const AR_SCHEDULE_SAVE = "/services/Automation/Schedules";
export const GET_AR_INVOICE_SCHEDULE_DATA = "/services/Automation/Schedules";
export const AUTOMATION_SCHEDULE_DELETE = "/services/Automation/Schedules";
//Custom log
export const API_CUSTOM_LOGS='/services/CustomLogs/GetCustomLogsAll';

//File Explorer
export const API_FILE_EXPLORER_FILES_GET = '/services/FileExplorer/getFiles/';
export const API_FILE_EXPLORER_DIRECTORIES_GET = '/services/FileExplorer/getDirectories';
export const API_FILE_EXPLORER_FILES_SEARCH = '/services/FileExplorer/SearchTree';
export const API_FILE_EXPLORER_LOAD_SELECTED_DIRECTORY = '/services/FileExplorer/loadDirectory';
export const API_FILE_EXPLORER_CREATE_FILE = '/services/FileExplorer/createFile/';
export const API_FILE_EXPLORER_CREATE_DIRECTORY = '/services/FileExplorer/createDirectory/';
export const API_FILE_EXPLORER_SHOW_DIRECTORIES_PRODUCTWISE = '/services/FileExplorer/Directory/ProductName/';
export const API_FILE_EXPLORER_CLIENT_FILES = '/services/FileExplorer/getClientFiles/';
export const API_DELETE_FILE_EXPLORER_FILES = '/services/FileExplorer/deleteFiles/';
export const API_MOVE_FILE_EXPLORER_FILES = '/services/FileExplorer/moveFiles/';
export const API_GET_DIRECTORY_DETAILS = '/services/FileExplorer/getDirectoryDetails/';
export const API_GET_DOWNLOAD_URL = '/services/FileExplorer/getDownloadUrl/';

//General Ledger
export const API_AR_GENERALLEDGER_CODE_GET = '/services/AR/GeneralLedger/Charges';
export const API_AR_GENERALLEDGER_CODE_SAVE = '/services/AR/GeneralLedger/Charges';
export const API_AR_GENERALLEDGER_CODE_COPY = '/services/AR/GeneralLedger/Charges/Copy';
export const API_AR_GENERALLEDGER_SUMMARY = '/services/AR/GeneralLedger/SummaryReport';
export const API_AR_GENERALLEDGER_SUMMARY_EXPORT = '/services/AR/GeneralLedger/SummaryReport/Export';

// Mention Email Service
export const API_EMAIL_MENTION_NOTIFICATION = '/services/Users/MentionedNotifications';

//Customer activity module
export const API_REPORTS_CUSTOMER_ACTIVITY_MODULE_BYCRITERIA_GET = '/services/Reports/CustomerActivities';
export const API_REPORTS_CUSTOMER_ACTIVITY_MODULE_PRINT = '/services/Reports/CustomerActivities/Print';
export const API_REPORTS_CUSTOMER_ACTIVITY_MODULE_EXPORT = '/services/Reports/CustomerActivities/Export';
export const API_REPORTS_CUSTOMER_ACTIVITY_MODULE_JOBJACKET_DETAILS_GET = '/services/Reports/CustomerActivities/Jobjacket';

