import React, { memo } from "react";
import PropTypes from "prop-types";

const Icon = (props) => {
  const {
    iconName,
    className = "",
    style = {},
    alt = "",
    folderName = "",
    onClick = () => { },
    title = "",
  } = props;
  let iconPath;

  try {
    if (folderName) {
      iconPath = require(`../images/${folderName}/${iconName}`);
    } else {
      iconPath = require(`../images/${iconName}`);
    }
  } catch (err) {
    iconPath = "";
  }

  return (
    <img
      src={iconPath}
      alt={alt || iconName}
      className={className}
      style={{ width: "25px", height: "25px", ...style }}
      onClick={onClick}
      title={title}
    />
  );
};

Icon.propTypes = {
  iconName: PropTypes.string.isRequired,
  className: PropTypes.string,
  style: PropTypes.object,
  alt: PropTypes.string,
  folderName: PropTypes.string,
  onClick: PropTypes.func,
  title: PropTypes.string,
};

export default memo(Icon);
