import {cloneElement} from "react";
import { createPortal } from "react-dom";
import PropTypes from "prop-types";

Popover.propTypes = {
    children: PropTypes.element.isRequired,
    refPosition: PropTypes.object.isRequired
}

export default function Popover(props) {
    const {refPosition, children} = props;
    
    const elementPosition = {top: refPosition.bottom, left: refPosition.left};
    
    const positionedElement = cloneElement(children, {style: children.style ? {...children.style, ...elementPosition}: elementPosition})
    
    return createPortal(positionedElement, document.querySelector("body"));
}